export interface QuickNavigatorHistoryProvider {
    navigate: (pageName: string, params: any) => void;
    replace?: (pageName: string, params: any) => void;
    goBack: () => void;
}

export const createQuickNavigator = <TNavigations>(
    historyProvider: QuickNavigatorHistoryProvider
) => {
    type NavigationKey = keyof TNavigations;

    const navigate = <TPageKey extends NavigationKey>(
        pageKey: TPageKey,
        ...params: TNavigations[TPageKey] extends void
            ? [undefined?]
            : [TNavigations[TPageKey]]
    ) => {
        console.log('Navigating to ', { pageKey, params: params[0] });

        historyProvider.navigate(`/${String(pageKey)}`, params[0]);
    };

    const replace = <TPageKey extends NavigationKey>(
        pageKey: TPageKey,
        ...params: TNavigations[TPageKey] extends void
            ? [undefined?]
            : [TNavigations[TPageKey]]
    ) => {
        console.log('Navigating to ', { pageKey, params: params[0] });

        historyProvider.replace(`/${String(pageKey)}`, params[0]);
    };

    const goBack = () => {
        historyProvider.goBack();
    };

    const getSearchParams = <TPageKey extends NavigationKey>(
        _: TPageKey
    ): TNavigations[TPageKey] | null => {
        const paramsObj: { [key: string]: string } = {};

        new URLSearchParams(window.location.search).forEach((value, key) => {
            paramsObj[key] = value;
        });

        if (Object.keys(paramsObj).length === 0) return null;

        return paramsObj as unknown as TNavigations[TPageKey];
    };

    return {
        navigate,
        replace,
        goBack,
        getSearchParams
    };
};

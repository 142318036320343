import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { Category } from 'shared';
import { appApiProvider } from './axiosApiProvider';

export class CategoryService extends QuickApiEntityService<Category> {
    constructor() {
        super(
            new QuickConsoleLogger('CategorieslService'),
            appApiProvider,
            'categories',
            'Category'
        );
    }
}

export const categoryService = new CategoryService();

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useController, UseControllerProps, UseFormSetError } from 'react-hook-form';
import { isImage } from 'utils/file';
import { showToast } from 'utils/toast';
import styles from './uploadFileButton.module.scss';

type UploadFileButtonProps = UseControllerProps & {
    text: string;
    setError: UseFormSetError<Record<string, string | number | boolean | number[]>>;
};

const UploadFileButton = ({ text, name, control, setError }: UploadFileButtonProps) => {
    const hiddenFileInput = React.useRef(null);
    const { field } = useController({ name, control });
    const [urlImg, setUrlImg] = useState<string>(field.value);

    useEffect(() => {
        if (typeof field.value === 'string') setUrlImg(field.value);
    }, [field.value]);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        if (isImage(fileUploaded.type)) {
            field.onChange(fileUploaded);
            const reader = new FileReader();
            reader.onload = e => {
                setUrlImg(e.target.result as string);
            };
            reader.readAsDataURL(fileUploaded);
        } else {
            // alert('פורמט לא תקין! ניתן להעלות קבצים מסוג: jpg, jpeg, png');
            showToast('פורמט לא תקין! ניתן להעלות קבצים מסוג: jpg, jpeg, png', 'error');
        }
    };

    return (
        <div className={styles.uploadFileButton}>
            <img
                alt={name}
                src={urlImg}
                className={classNames({ [styles.img]: field.value })}
            />
            <span className={styles.uploadFileButton} onClick={handleClick}>
                {text}
            </span>
            <input
                className={styles.uploadFileLink}
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                accept="image/*"
            />
        </div>
    );
};

export default UploadFileButton;

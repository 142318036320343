import { ListFormField } from 'components/Form/formTypes';
import { GenericList } from 'components/GenericList/GenericList';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { useController, UseControllerProps } from 'react-hook-form';

interface ListProps extends UseControllerProps {
    dataObj: ListFormField;
}

const List = ({ name, dataObj, control }: ListProps) => {
    const { field } = useController({
        name,
        control
    });

    const listItems: GenericListItem[] = field.value?.map?.((row: any) => ({
        id: row.id,
        columns: dataObj.listItem(row, field.onChange, field.value)
    }));

    return <GenericList key={name} listItems={listItems} />;
};

export default List;

import { useAuth } from 'hooks';
import { appNavigator } from 'router';
import { GeneralNavRoutes } from 'shared';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { getCurrentUser, getIsUserAuthenticated } from 'store';
import { useSelector } from 'react-redux';
import { InteractionStatus } from '@azure/msal-browser';

export const Login = () => {
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useSelector(getIsUserAuthenticated);
    const currentUser = useSelector(getCurrentUser);
    const { loginUser, getToken } = useAuth();

    useEffect(() => {
        console.log('onLoad: ', isAuthenticated);

        const onLoad = async () => {
            if (inProgress === InteractionStatus.None) {
                if (!currentUser || !instance.getActiveAccount()) await loginUser();

                if (!isAuthenticated) {
                    getToken();
                } else {
                    appNavigator.navigate(GeneralNavRoutes.Main);
                }
            }
        };
        onLoad();
    }, [isAuthenticated, inProgress, loginUser, getToken, currentUser, instance]);

    return (
        <div style={{ color: 'black', margin: 'auto' }}>
            <h1>מבצע התחברות...</h1>
        </div>
    );
};

import { PropsWithChildren } from 'react';
import { FormControl, InputLabel, Select as MUISelect } from '@mui/material';
import { SelectField } from 'components/Form/formTypes';
import { useController, UseControllerProps } from 'react-hook-form';
import ValidationMessage from '../ValidationMessage/ValidationMessage';

type SelectProps = PropsWithChildren &
    UseControllerProps & {
        dataObj: SelectField;
        rootClass: string;
    };

const Select = ({ name, control, dataObj, rootClass, children }: SelectProps) => {
    const { field, fieldState } = useController({
        name,
        control,
        rules: dataObj.options,
        defaultValue: ''
    });

    return (
        <FormControl variant="standard" classes={{ root: rootClass }}>
            <InputLabel shrink htmlFor={name}>
                {dataObj.description}
            </InputLabel>
            <MUISelect
                {...field}
                id={name}
                displayEmpty
                renderValue={selected =>
                    !selected ? (
                        <em>בחר</em>
                    ) : (
                        dataObj.optionItems.find(item => item.value === selected)
                            .desctiption
                    )
                }>
                {children}
            </MUISelect>
            <ValidationMessage fieldValue={dataObj} error={fieldState.error} />
        </FormControl>
    );
};

export default Select;

import classNames from 'classnames';
import {
    CategoryFiltersData,
    FiltersWithConstantValues,
    FiltersWithDynamicValues
} from 'pages/Main/main.model';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { getCategories } from 'store/selectors/categories.selector';
import styles from './Categories.module.scss';

interface CategoriesProps {
    categoryState: CategoryFiltersData;
    setCategory: Dispatch<SetStateAction<CategoryFiltersData>>;
}

const Categories = ({ categoryState, setCategory }: CategoriesProps) => {
    const categories = useSelector(getCategories);

    return (
        <div className={styles.subCategory}>
            <span
                className={classNames({
                    [styles.active]: categoryState.type === FiltersWithConstantValues.ALL
                })}
                onClick={() => setCategory({ type: FiltersWithConstantValues.ALL })}>
                הכל
            </span>
            <span
                className={classNames({
                    [styles.active]:
                        categoryState.type === FiltersWithConstantValues.FAVORITES
                })}
                onClick={() =>
                    setCategory({ type: FiltersWithConstantValues.FAVORITES })
                }>
                מועדפים
            </span>
            {categories?.map(category => (
                <span
                    key={category.id}
                    className={classNames({
                        [styles.active]:
                            categoryState.type === FiltersWithDynamicValues.CATEGORIES &&
                            categoryState.id === category.id
                    })}
                    onClick={() =>
                        setCategory({
                            type: FiltersWithDynamicValues.CATEGORIES,
                            id: category.id
                        })
                    }>
                    {category.name}
                </span>
            ))}
        </div>
    );
};

export default Categories;

import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { GroupFetchDTO } from 'shared';
import { appApiProvider } from './axiosApiProvider';

export class GroupService extends QuickApiEntityService<GroupFetchDTO> {
    constructor() {
        super(new QuickConsoleLogger('GroupService'), appApiProvider, 'groups', 'Group');
    }

    public async getUserGroups(): Promise<GroupFetchDTO[]> {
        return this.getOrThrow({ url: '/getUserGroups' });
    }
}

export const groupService = new GroupService();

export enum FiltersWithConstantValues {
    ALL = 'all',
    FAVORITES = 'favorites'
}

export enum FiltersWithDynamicValues {
    CATEGORIES = 'categories'
}

interface DynamicFiltersData {
    type: FiltersWithDynamicValues;
    id: number;
}

interface ConstantFiltersData {
    type: 'all' | 'favorites' /* FiltersWithConstantValues */;
}

export type CategoryFiltersData = DynamicFiltersData | ConstantFiltersData;

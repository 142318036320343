import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import { isEmpty } from 'lodash';
import { getFilenameFromURL } from 'quickode.infrastructure.js';
import { FC, MutableRefObject, useMemo, useRef } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { ReactComponent as UploadIcon } from '../../assets/images/svg/upload-icon.svg';
import { useFormStyles } from '../quick-form-styles.hook';
import { QFFile } from '../quick-form.model';

const getFileSizeInMB = (file: File): number => {
    return Number((file.size / 1024 / 1024).toFixed(4));
};

const UploadFileContainer = styled('section')(({ theme }) => {
    let borderColor = '#CEDBDC';

    if (theme?.palette?.secondary && theme.palette?.secondary?.light) {
        borderColor = theme.palette.secondary.light;
    }

    return {
        border: `${borderColor} dashed 1px`,
        borderRadius: '3px',
        height: '50px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    };
});

const FileNameContainer = styled('div')(({ theme }) => {
    let colorOfBackground = '#CEDBDC';

    if (theme?.palette?.secondary && theme.palette?.secondary?.light) {
        colorOfBackground = theme.palette.secondary.light;
    }

    return {
        width: '100%',
        height: '50px',
        background: `${colorOfBackground}30`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    };
});

interface FilePresenterProps {
    file: File | string;
    onRemove: () => void;
    readOnly: boolean;
}

const FilePresenter: FC<FilePresenterProps> = ({ file, onRemove, readOnly }) => {
    const fileName = useMemo(() => {
        if (typeof file === 'string') {
            return getFilenameFromURL(file);
        }

        return file.name;
    }, [file]);
    return (
        <FileNameContainer>
            <Typography
                sx={{
                    width: '100%',
                    margin: '0 10px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                {fileName}
            </Typography>
            {!readOnly && (
                <IconButton onClick={onRemove}>
                    <CloseRoundedIcon sx={{ color: 'black' }} />
                </IconButton>
            )}
        </FileNameContainer>
    );
};

interface FileUploaderProps {
    placeholder: string;
    hiddenFileInputRef: MutableRefObject<any>;
    allowedFileTypes: {
        types: string[];
        errMess?: string;
    };
    handleClick: (_event: any) => void;
    onInputChanged: (input: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
}

const FileUploader: FC<FileUploaderProps> = ({
    placeholder,
    hiddenFileInputRef,
    allowedFileTypes,
    handleClick,
    onInputChanged,
    required
}) => {
    return (
        <UploadFileContainer>
            <Button
                sx={{ width: '100%', height: '100%', color: 'black' }}
                variant="text"
                startIcon={<UploadIcon />}
                onClick={handleClick}>
                <Typography color="common.black">{placeholder}</Typography>
                <input
                    ref={hiddenFileInputRef}
                    type="file"
                    hidden
                    accept={allowedFileTypes.types.join(',')}
                    onChange={onInputChanged}
                />
                {required && (
                    <span style={{ color: '#f44336', transform: 'translate(1px,-2px)' }}>
                        *
                    </span>
                )}
            </Button>
        </UploadFileContainer>
    );
};

export interface QuickFileProps extends Omit<QFFile, 'type'> {
    name: string;
    style?: React.CSSProperties;
    control: Control<FieldValues, any>;
    allowedFileTypes: {
        types: string[];
        errMess?: string;
    };
    allowedFileSizeInMB: { size: number; errMess?: string };
}

export const QuickFile: FC<QuickFileProps> = ({
    name,
    placeholder,
    style,
    control,
    allowedFileTypes,
    allowedFileSizeInMB,
    readOnly,
    required
}) => {
    // Reference to the hidden file input element
    const hiddenFileInputRef = useRef(null);
    const { sectionStyle } = useFormStyles(name, style);

    const handleClick = (_event: any) => {
        hiddenFileInputRef.current.click();
    };
    console.log(readOnly);

    return (
        <section key={`section-${name}`} style={sectionStyle}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl
                        style={{
                            width: '100%'
                        }}>
                        {value ? (
                            <FilePresenter
                                file={value}
                                readOnly={readOnly}
                                onRemove={() => {
                                    onChange(null);
                                }}
                            />
                        ) : (
                            <FileUploader
                                required={required}
                                placeholder={placeholder}
                                hiddenFileInputRef={hiddenFileInputRef}
                                allowedFileTypes={allowedFileTypes}
                                handleClick={handleClick}
                                onInputChanged={(
                                    input: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    let file = null;
                                    if (!isEmpty(input.target.files)) {
                                        file = input.target.files[0];
                                        const fileSize = getFileSizeInMB(file);
                                        if (fileSize > allowedFileSizeInMB.size) {
                                            onChange(file);
                                            return;
                                        }
                                        onChange(file);
                                        return;
                                    }
                                    onChange(null);
                                }}
                            />
                        )}
                        {error?.message ? (
                            <FormHelperText error>{error.message}</FormHelperText>
                        ) : null}
                    </FormControl>
                )}
            />
        </section>
    );
};

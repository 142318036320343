import { InteractionType } from '@azure/msal-browser';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { loginRequest } from './authConfig';

export const ComponentGuard = ({ ...props }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const activeAccount = instance.getActiveAccount() || instance.getAllAccounts()[0];

        if (!activeAccount?.idTokenClaims?.groups) return;

        const userGroups: String[] = activeAccount.idTokenClaims.groups as String[];

        const hasRequiredGroups = props.requriedGroups.some((group: any) =>
            userGroups.includes(group)
        );

        setIsAuthorized(hasRequiredGroups);
    }, [instance, props]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}>
            {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                isAuthorized ? <div>{props.children}</div> : <></>
            }
        </MsalAuthenticationTemplate>
    );
};

import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { useDialog } from 'quickode.infrastructure.react';
import { UseFormSetValue } from 'react-hook-form';
import { GroupFetchDTO } from 'shared';
import { DialogRes } from '../UsersListDialog/userListDialog.config';
import UsersListDialog from '../UsersListDialog/UsersListDialog';

interface UserSectionActionsProps {
    keyForm: string;
    group: GroupFetchDTO;
    setValue: UseFormSetValue<any>;
}

const UserSectionActions = ({ keyForm, group, setValue }: UserSectionActionsProps) => {
    const { openDialog } = useDialog();

    const openAddingUserDevicesDialog = async () => {
        const userDeviceIds = group?.users?.map((user: any) => user.id);
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return (
                    <UsersListDialog
                        handleClose={handleClose}
                        userDeviceIds={userDeviceIds}
                    />
                );
            }
        });

        if (res.actionCode === 'Cancel') {
            console.log('cancel!');
        } else if (res.actionCode === 'Save') {
            // setValue from useFormContext does not affect isDirty even with the shouldDirty option. link: https://github.com/react-hook-form/react-hook-form/issues/72#issuecomment-1096813727
            setValue(
                keyForm,
                res.selectedUsers.map(
                    user => ({
                        id: user.id,
                        email: user.email,
                        img: user.img,
                        roleName: user.roleName,
                        userId: user.id
                    }),
                    {
                        shouldDirty: true
                    }
                )
            );
        }
    };

    return (
        <IconButton
            sx={{ color: 'white' }}
            size="small"
            onClick={openAddingUserDevicesDialog}>
            <AddIcon />
        </IconButton>
    );
};

export default UserSectionActions;

import { createSelector } from 'reselect';
import { AppState } from 'store';

const getToken = (state: AppState) => state.accessToken;

export const getCurrentToken = createSelector([getToken], token => token);

export const getIsUserAuthenticated = createSelector([getCurrentToken], token =>
    Boolean(token)
);

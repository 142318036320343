import { FC } from 'react';
import { List } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useTranslate } from 'hooks';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { sideMenuRoutes } from 'router';
import { getCurrentUserRole } from 'store';
import { ReactComponent as NavLinkSelectedRec } from 'assets/mainLayout/menu/NavLinkSelectedRec.svg';
import classnames from 'classnames';
import ScrollbarContainner from 'components/ScrollbarContainner/ScrollbarContainner';
import { Button } from 'components';
import styles from './sideMenu.module.scss';

interface NavOptionProps {
    isActive: boolean;
    translationKey: string;
}

const NavOption: FC<NavOptionProps> = ({ isActive, translationKey }) => {
    const { simpleTranslate } = useTranslate();
    return (
        <div
            className={classnames({
                [styles.navLinkData]: true,
                [styles.active]: isActive
            })}>
            {isActive && <NavLinkSelectedRec className={styles.navLinkSelectedRec} />}
            <ListItemText
                classes={{ primary: styles.listItemText }}
                primary={simpleTranslate(translationKey)}
            />
        </div>
    );
};

export const SideMenu: FC<{ handleDrawerToggle: () => void }> = ({
    handleDrawerToggle
}) => {
    const currentUserRole = useSelector(getCurrentUserRole);
    const navigate = useNavigate();
    const { simpleTranslate } = useTranslate();

    const menuItems = Object.entries(sideMenuRoutes)
        .filter(
            ([, routeValue]) =>
                !routeValue.authRoles || routeValue.authRoles.includes(currentUserRole)
        )
        .map(([key, { path, translationKey }]) => (
            <NavLink
                className={styles.navLink}
                key={key}
                to={path}
                onClick={handleDrawerToggle}
                style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                    <NavOption isActive={isActive} translationKey={translationKey} />
                )}
            </NavLink>
        ));

    const logout = () => {
        navigate('/auth/logout');
    };

    return (
        <>
            <ScrollbarContainner>
                <List className={styles.sideMenu}>{menuItems}</List>
            </ScrollbarContainner>
            <Button
                style={{ width: '50%', fontSize: '18px', alignSelf: 'center' }}
                onClick={logout}>
                {simpleTranslate('sideNav.logout')}
            </Button>
        </>
    );
};

import { Dispatch, PropsWithChildren, SetStateAction, useRef } from 'react';
import { DividerProps } from '@mui/material';
import { useIsScrollable } from 'hooks';
import classNames from 'classnames';
import styles from './scrollbarContainner.module.scss';

type ScrollbarContainnerProps = PropsWithChildren &
    DividerProps & {
        setIsScrollable?: (
            isScrollable: boolean
        ) => void | Dispatch<SetStateAction<boolean>>;
        className?: string;
    };

const ScrollbarContainner = ({
    children,
    setIsScrollable,
    className,
    ...props
}: ScrollbarContainnerProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const setIsScrollableCB = (isScrollable: boolean) => {
        setIsScrollable?.(isScrollable);
    };
    useIsScrollable(ref, setIsScrollableCB);

    return (
        <div
            {...props}
            ref={ref}
            className={classNames(styles.scrollbarContainner, className)}>
            <div className={styles.innerScrollbar}>{children}</div>
        </div>
    );
};

export default ScrollbarContainner;

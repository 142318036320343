import { Swiper as SwiperWrapper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { breackpoints } from 'layout/theme/breackpoints';
import { Channel } from 'shared';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
    actions,
    getSelectedUserChannel,
    getSelectedUserDevice,
    getSelectedUserGroup
} from 'store';
import { useBreakpointsMediaQuery } from 'hooks/useBreakpointsMediaQuery';
import './channelCarousel.scss';
import { deviceService } from 'api';

interface ChannelCarouselProps {
    channels: Channel[];
}

export const ChannelCarousel = ({ channels }: ChannelCarouselProps) => {
    const selectedUserChannel = useSelector(getSelectedUserChannel);
    const selectedDevice = useSelector(getSelectedUserDevice);
    const selectedGroup = useSelector(getSelectedUserGroup);

    const isMobile = useBreakpointsMediaQuery('breackpointTablets');

    const setSelectedChannel = (channel: Channel) => {
        let devicesIds = [];
        if (selectedGroup) {
            devicesIds = [...selectedGroup.devices.map(group => group.id)];
        } else {
            devicesIds = [selectedDevice.id];
        }

        actions.userSelections.partialUpdate({ channel });
        deviceService.changeChannel(devicesIds, channel.address, selectedGroup?.id);
    };

    return (
        <SwiperWrapper
            navigation
            centeredSlides
            initialSlide={0}
            spaceBetween={30}
            speed={500}
            modules={[Navigation]}
            pagination={{ clickable: true }}
            breakpoints={{
                0: { slidesPerView: 'auto' },
                [parseFloat(breackpoints.breackpointTablets)]: { slidesPerView: 3 },
                800: { slidesPerView: 3 },
                1000: { slidesPerView: 4 },
                1200: { slidesPerView: 5 },
                1350: { slidesPerView: 6 },
                1500: { slidesPerView: 7 },
                1800: { slidesPerView: 8 }
            }}>
            {channels.map(channel => (
                <SwiperSlide
                    key={channel.id}
                    onClick={() => setSelectedChannel(channel)}
                    className={classnames({
                        selectedChannel: channel.id === selectedUserChannel?.id
                    })}>
                    <div
                        className={classnames({
                            innerSwiperSlideContainer: true,
                            activeInnerSwiperSlideContainer:
                                channel.id === selectedUserChannel?.id
                        })}>
                        <img
                            src={
                                isMobile && channel.id === selectedUserChannel?.id
                                    ? channel.channelLogo
                                    : channel.channelLogoMobile
                            }
                            alt={`${channel.channelNameEN}`}
                        />
                    </div>
                </SwiperSlide>
            ))}
        </SwiperWrapper>
    );
};

import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { useDialog } from 'quickode.infrastructure.react';
import { UseFormSetValue } from 'react-hook-form';
import { Device, GroupFetchDTO } from 'shared';
import { DialogRes } from '../DeviceListDialog/deviceListDialog.config';
import DevicesListDialog from '../DeviceListDialog/DevicesListDialog';

interface DeviceSectionActionsProps {
    keyForm: string;
    group: GroupFetchDTO;
    setValue: UseFormSetValue<any>;
}

const DeviceSectionActions = ({
    keyForm,
    group,
    setValue
}: DeviceSectionActionsProps) => {
    const { openDialog } = useDialog();

    const openAddingDeviceDialog = async () => {
        const groupDeviceIds = group?.devices?.map((device: Device) => device.id);
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return (
                    <DevicesListDialog
                        handleClose={handleClose}
                        groupDeviceIds={groupDeviceIds}
                    />
                );
            }
        });

        if (res.actionCode === 'Cancel') {
            console.log('cancel!');
        } else if (res.actionCode === 'Save') {
            // setValue from useFormContext does not affect isDirty even with the shouldDirty option. link: https://github.com/react-hook-form/react-hook-form/issues/72#issuecomment-1096813727
            setValue(
                keyForm,
                res.selectedDevices.map(
                    device => ({
                        id: device.id,
                        neme: device.neme
                    }),
                    {
                        shouldDirty: true
                    }
                )
            );
        }
    };

    return (
        <IconButton sx={{ color: 'white' }} size="small" onClick={openAddingDeviceDialog}>
            <AddIcon />
        </IconButton>
    );
};

export default DeviceSectionActions;

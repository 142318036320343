import { PropsWithChildren } from 'react';
import { Section, MappingFieldsType } from 'components/Form/formTypes';
import classNames from 'classnames';
import { getDisplayModeObj } from 'store/selectors/displayMode.selector';
import { useSelector } from 'react-redux';
import EntityForm from 'components/EntityForm/EntityForm';
import styles from './entitiesPageLayout.module.scss';

interface FormNeeded<T> {
    type: 'form';
    entityFields: MappingFieldsType<string>;
    entityAdditionalSections?: Section<string>[];
    entityFieldValues: Record<string, any>;
    onDelete: () => void;
    onSubmit: (entity: T) => void;
}

interface CustomComponent {
    type: 'component';
    viewComponent: JSX.Element;
}

interface BaseEntitiesPageLayoutProps<T> {
    selectedEntity: T;
    singularEntityName: string;
}

export type EntitiesPageLayoutProps<T> = PropsWithChildren<
    BaseEntitiesPageLayoutProps<T> & (FormNeeded<T> | CustomComponent)
>;

type EntitiesPageLayout<T> = PropsWithChildren<{
    isEditMode: boolean;
    entityFields: MappingFieldsType<string>;
    entityAdditionalSections?: Section<string>[];
    entityFieldValues: Record<string, string | number | boolean | number[]>;
    formTitle: string;
    onDelete: () => void;
    onSubmit: (entity: T) => void;
}>;

// You mustn't destruct the type from props
// This will cause the props object to not be typed
export const EntitiesPageLayout = <T extends unknown>({
    children,
    selectedEntity,
    singularEntityName,
    ...props
}: EntitiesPageLayoutProps<T>) => {
    const displayModeObj = useSelector(getDisplayModeObj);

    const title = `טופס ${selectedEntity ? 'עריכת' : 'הוספת'} ${singularEntityName} ${
        selectedEntity ? '' : 'חדש'
    }`;

    const isMobileAndListMode =
        displayModeObj.isDesktop === false && displayModeObj.displayMode === 'list';
    const isMobileAndFormMode =
        displayModeObj.isDesktop === false && displayModeObj.displayMode === 'form';

    const getComponent = () => {
        switch (props.type) {
            case 'form':
                return (
                    <EntityForm
                        {...props}
                        className={classNames({
                            [styles.formComponentContainner]: true,
                            [styles.formIsListMode]: isMobileAndListMode,
                            [styles.formIsFormMode]: isMobileAndFormMode
                        })}
                        isEditMode={!!selectedEntity}
                        formTitle={title}
                    />
                );
            case 'component':
                return (
                    <div
                        className={classNames({
                            [styles.formComponentContainner]: true,
                            [styles.formIsListMode]: isMobileAndListMode,
                            [styles.formIsFormMode]: isMobileAndFormMode
                        })}>
                        {props.viewComponent}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.entitiesPageLayout}>
            <div
                className={classNames({
                    [styles.listComponentContainner]: true,
                    [styles.listIsListMode]: isMobileAndListMode,
                    [styles.listIsFormMode]: isMobileAndFormMode
                })}>
                {children}
            </div>
            {getComponent()}
        </div>
    );
};

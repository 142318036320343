import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { ChannelModule } from 'shared';
import styles from './modules.module.scss';

interface CategoriesProps {
    module: ChannelModule;
    setModule: Dispatch<SetStateAction<ChannelModule>>;
}

const Modules = ({ module, setModule }: CategoriesProps) => {
    const clickHandler = (selectedModule: ChannelModule) => {
        setModule(selectedModule);
    };

    return (
        <div className={styles.module}>
            <span
                className={classNames({ [styles.active]: module === ChannelModule.TV })}
                onClick={() => clickHandler(ChannelModule.TV)}>
                טלוויזיה
            </span>
            <span
                className={classNames({
                    [styles.active]: module === ChannelModule.RADIO
                })}
                onClick={() => clickHandler(ChannelModule.RADIO)}>
                רדיו
            </span>
        </div>
    );
};

export default Modules;

import { messageService } from 'api';
import { PopUpMessage } from 'components/PopUpMessage/PopUpMessage';
import { useEffect, useState } from 'react';
import { MessageDTO } from 'shared';
import styles from './popupmessagelist.module.scss';

export const PopUpMessageList = () => {
    const [messages, setMessages] = useState([]);
    const [removedMessageIds, setRemovedMessageIds] = useState([]);

    useEffect(() => {
        messageService.getUserMessages().then((data: MessageDTO[]) => {
            setMessages(data);
        });
    }, []);

    const setReadedMessage = (id: number) => {
        setRemovedMessageIds(prevIds => [...prevIds, id]);
        messageService.setHasReaded(id).then((message: MessageDTO) => {
            setMessages((prevData: MessageDTO[]) => {
                return prevData.filter(m => m.id !== message.id);
            });
        });
    };

    return (
        <div className={styles['message-list']}>
            {messages?.length > 0 &&
                messages.slice(0, 5)?.map((message: MessageDTO, index: number) => {
                    const isRemoved = removedMessageIds.includes(message.id);
                    return (
                        <div
                            className={`${styles['message-container']} ${
                                isRemoved ? styles['slide-out'] : ''
                            }`}
                            key={message.id}>
                            <PopUpMessage
                                message={message}
                                setReaded={setReadedMessage}
                                index={index}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

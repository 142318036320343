import { useSelector } from 'react-redux';
import './global-spinner.scss';
import { getSpinnerState } from './global-spinner.selectors';

interface GlobalSpinnerProps {
    isForceVisible?: boolean; // will be used for the implementation of the component directly (no by hook)
}

export const GlobalSpinner = ({ isForceVisible = false }: GlobalSpinnerProps) => {
    const spinnerState = useSelector(getSpinnerState);

    if (!spinnerState?.isVisible && !isForceVisible) {
        return null;
    }

    return (
        <div className="SpinnerContainer">
            <div>
                <div className="sk-chase">
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                </div>
                <p className="LoadingText">{spinnerState.text || 'Loading...'}</p>
            </div>
        </div>
    );
};

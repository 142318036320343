import { Dispatch, SetStateAction } from 'react';
import { ChannelModule } from 'shared';
import Modules from './Modules/Modules';
import Categories from './Categories/Categories';
import styles from './tabs.module.scss';
import { CategoryFiltersData } from '../main.model';

interface TabsProps {
    module: ChannelModule;
    setModule: Dispatch<SetStateAction<ChannelModule>>;
    category: CategoryFiltersData;
    setCategory: Dispatch<SetStateAction<CategoryFiltersData>>;
}

const Tabs = ({ module, setModule, category, setCategory }: TabsProps) => {
    return (
        <div className={styles.tabs}>
            <Modules module={module} setModule={setModule} />
            <Categories categoryState={category} setCategory={setCategory} />
        </div>
    );
};

export default Tabs;

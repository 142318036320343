import { useEffect, useState } from 'react';
import { DeviceDTO } from 'shared';

import { InputFilter } from 'components/InputFilter/InputFilter';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { Button, Checkbox, GenericList, ScrollbarContainner } from 'components';
import DialogBody from 'components/DialogBody/DialogBody';
import { deviceService } from 'api';
import { showToast } from 'utils/toast';
import styles from './deviceListDialog.module.scss';
import { DialogRes, DialogSaveRes } from './deviceListDialog.config';

interface DevicesListDialogProps {
    handleClose: (res: DialogRes) => void;
    groupDeviceIds: number[];
}

const DevicesListDialog = ({ handleClose, groupDeviceIds }: DevicesListDialogProps) => {
    const [devices, setDevices] = useState<null | DeviceDTO[]>(null);
    const [filterDevices, setFilterDevices] = useState<string>('');
    const [selectedDevicesIdsSet, setSelectedDevicesIdsSet] = useState<Set<number>>(
        new Set(groupDeviceIds)
    );

    useEffect(() => {
        deviceService
            .getEntities()
            .then(devicesData => {
                setDevices(devicesData);
            })
            .catch(err => {
                console.log(err);
                // alert('Fetch all devices failed!');
                showToast('Fetch all devices failed!', 'error');
            });
    }, []);

    const save = (): DialogSaveRes => {
        return {
            actionCode: 'Save',
            selectedDevices: devices.filter(device =>
                selectedDevicesIdsSet.has(device.id)
            )
        };
    };

    const checkHandler = (deviceid: number, checked: boolean) => {
        setSelectedDevicesIdsSet(set => {
            if (checked) {
                return new Set(set.add(deviceid));
            }
            set.delete(deviceid);
            return new Set(set);
        });
    };

    const selectAll = () => {
        setSelectedDevicesIdsSet(new Set(devices.map(device => device.id)));
    };

    const devicesList = devices
        ?.filter(device =>
            device.neme.toLowerCase().includes(filterDevices.toLowerCase())
        )
        .map?.<GenericListItem>(device => {
            const isChecked = Boolean(selectedDevicesIdsSet.has(device.id));
            return {
                key: device.id,
                id: device.id,
                onClick: () => checkHandler(device.id, !isChecked),
                columns: [
                    [
                        {
                            type: 'title',
                            title: device.neme
                        }
                    ],
                    [
                        {
                            type: 'component',
                            component: (
                                <Checkbox
                                    checked={isChecked}
                                    handleChange={(_event, checked) => {
                                        checkHandler(device.id, checked);
                                    }}
                                />
                            )
                        }
                    ]
                ]
            };
        });

    return (
        <DialogBody
            header={
                <>
                    <div className={styles.titleContainer}>
                        <span className={styles.title}>הוספת מכשירים</span>
                        <span className={styles.selectedCount}>
                            סך הכל {selectedDevicesIdsSet?.size} נבחרו
                        </span>
                    </div>
                    <Button variant="text" isActive={false} onClick={selectAll}>
                        בחר הכל
                    </Button>
                </>
            }
            footer={
                <>
                    <Button onClick={() => handleClose(save())}>שמירה</Button>
                    <Button
                        variant="text"
                        isActive={false}
                        onClick={() => {
                            if (window.confirm('האם ברצונך לבטל את השינויים?')) {
                                handleClose({ actionCode: 'Cancel' });
                            }
                        }}>
                        ביטול
                    </Button>
                </>
            }>
            <InputFilter
                filter={filterDevices}
                setFilter={setFilterDevices}
                placeholder="חיפוש מכשירים"
            />
            <ScrollbarContainner className={styles.genericListContainer}>
                <GenericList listItems={devicesList} />
            </ScrollbarContainner>
        </DialogBody>
    );
};

export default DevicesListDialog;

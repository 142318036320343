import { Button } from 'components';
import { setDisplayMode } from 'store';
import styles from './entityFormActionsMobile.module.scss';

interface EntityFormActionsMobileProps {
    isSaveValid: boolean;
}

const EntityFormActionsMobile = ({ isSaveValid }: EntityFormActionsMobileProps) => {
    return (
        <div className={styles.entityFormActionsMobile}>
            <Button type="submit" disabled={isSaveValid}>
                שמירה
            </Button>
            <Button isActive={false} onClick={() => setDisplayMode('list')}>
                ביטול
            </Button>
        </div>
    );
};

export default EntityFormActionsMobile;

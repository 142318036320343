import { actions, setDisplayMode } from 'store';
import { useSelector } from 'react-redux';
import { getCategories } from 'store/selectors/categories.selector';
import { categoryService, channelService } from 'api';
import { ListItemType, ToggleButtonGroup } from 'components';
import Entity from 'components/Entity/Entity';
import { useEffect, useState } from 'react';
import { Channel, ChannelModule } from 'shared';
import { showToast } from 'utils/toast';
import { getIsTextFiltered } from 'utils/filter';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import {
    getChannelFields,
    getChannelFieldsValues,
    ChannelFormValues
} from './channel.config';

const Channels = () => {
    const [channels, setChannels] = useState<{
        [ChannelModule.TV]: Channel[];
        [ChannelModule.RADIO]: Channel[];
    }>({ tv: [], radio: [] });
    const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
    const [channelFieldValues, setChannelFieldsValues] = useState<ChannelFormValues>(
        getChannelFieldsValues()
    );
    const [channelFilter, setChannelFilter] = useState<string>('');
    const [channelToggle, setChannelToggle] = useState<ChannelModule>(ChannelModule.TV);

    const categories = useSelector(getCategories);

    const fetchAllData = (options?: { noSetSelectedChannel: boolean }) => {
        channelService
            .getEntities()
            .then(data => {
                const tvChannels: Channel[] = [];
                const radioChannels: Channel[] = [];
                data.map(channel =>
                    channel.moduleType === ChannelModule.TV
                        ? tvChannels.push(channel)
                        : radioChannels.push(channel)
                );
                setChannels({ radio: radioChannels, tv: tvChannels });
                if (!options?.noSetSelectedChannel) {
                    setSelectedChannel(tvChannels[0]);
                }
            })
            .catch(err => {
                showToast('The channels fetch failed with err: ' + err, 'error');
                // alert('The channels fetch failed with err: ' + err);
                console.log('The channels fetch failed with err: ' + err);
            });
        categoryService
            .getEntities()
            .then(data => {
                actions.categories.set(data);
            })
            .catch(err => {
                console.log(err);
            });
        setDisplayMode('list');
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setChannelFieldsValues(getChannelFieldsValues(selectedChannel));
    }, [selectedChannel]);

    const updateSelectedEntity = (channel?: Channel | null) => {
        setSelectedChannel(channel);
    };

    const onSubmit = (channel?: Channel) => {
        if (selectedChannel?.id) {
            console.log('Channel editing');
            channelService
                .update({ ...selectedChannel, ...channel })
                .then(resData => {
                    // alert('The channel update was successful!');
                    showToast('The channel update was successful!', 'success');

                    console.log('The channel update was successful ' + resData);
                    fetchAllData({ noSetSelectedChannel: true });
                })
                .catch(err => {
                    // alert('The channel update failed with err: ' + err);
                    showToast('The channel update failed with err: ' + err, 'error');

                    console.log('The channel update failed with err: ' + err);
                });
        } else {
            console.log('Channel creation');
            channelService
                .create(channel)
                .then(data => {
                    // alert('The channel create was successful!');
                    showToast('The channel create was successful!', 'error');
                    console.log('The channel create was successful ' + data);
                    fetchAllData();
                })
                .catch(err => {
                    // alert('The channel create failed with err: ' + err);
                    showToast('The channel create failed with err: ' + err, 'error');
                    console.log('The channel create failed with err: ' + err);
                });
        }
    };

    const onDeleteHandler = () => {
        channelService
            .remove(selectedChannel)
            .then(resData => {
                console.log(resData);
                fetchAllData();
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        setSelectedChannel(channels[channelToggle][0]);
    }, [channelToggle, channels]);

    const filteredChannelListItems: ListItemType[] = channels[channelToggle]
        .filter(
            channel =>
                getIsTextFiltered(
                    channel.channelNameEN.toLowerCase(),
                    channelFilter.toLowerCase()
                ) || getIsTextFiltered(channel.channelNameHE, channelFilter)
        )
        .map(channel => ({
            id: channel.id,
            title: channel.channelNameHE,
            subTitle: channel.channelNameEN,
            img: channel.channelLogo,
            onClick: () => {
                updateSelectedEntity(channel);
                setDisplayMode('form');
            }
        }));

    const entityFields = getChannelFields(categories);

    const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) return;

        // the filtered list of channels.
        const filteredChannels = channels[channelToggle];

        // moving the source item to the new location
        const sourceChannel = filteredChannels.splice(sourceIndex, 1)[0];
        filteredChannels.splice(destinationIndex, 0, sourceChannel);

        // increment/decremention of all ordinal numbers of items range
        const editedChannels = filteredChannels.map((channel, index) => ({
            ...channel,
            ordinalNum: index + 1
        }));

        setChannels(prev => ({ ...prev, [channelToggle]: editedChannels }));

        // get only the items that will change
        const channelsForEdit = editedChannels.slice(
            Math.min(sourceIndex, destinationIndex),
            Math.max(sourceIndex, destinationIndex) + 1
        );

        channelService
            .changeOrdinalNum(channelsForEdit)
            .then(resData => {
                console.log(
                    'The ordinal number of the channels has been updated! Res data: ',
                    resData
                );
                showToast(
                    'The ordinal number of the channels has been updated!',
                    'success'
                );
                // alert('The ordinal number of the channels has been updated!');
            })
            .catch(err => {
                console.log(
                    'Updating the ordinal number of the channels failed with err: ',
                    err
                );
                showToast(
                    'Updating the ordinal number of the channels failed with err: ' + err,
                    'error'
                );
                // alert(
                //     'Updating the ordinal number of the channels failed with err: ' + err
                // );
                fetchAllData();
            });
    };

    const listActionComponent = (
        <ToggleButtonGroup
            value={channelToggle}
            onChangeHandler={value => {
                if (value) setChannelToggle(value);
            }}
            items={[
                { value: ChannelModule.RADIO, description: 'רדיו' },
                { value: ChannelModule.TV, description: 'טלוויזיה' }
            ]}
        />
    );

    return (
        <Entity
            type="form"
            singularEntityName="ערוץ"
            pluralEntityName="ערוצים"
            entityFields={entityFields}
            entityFieldValues={channelFieldValues}
            selectedEntity={selectedChannel}
            onSubmit={onSubmit}
            onDelete={onDeleteHandler}
            updateSelectedEntity={updateSelectedEntity}
            filteredEntityListItems={filteredChannelListItems}
            entityFilter={channelFilter}
            setEntityFilter={setChannelFilter}
            dragableListObj={{ onDragEnd }}
            listActionComponent={listActionComponent}
        />
    );
};

export default Channels;

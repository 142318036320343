import { Draggable } from 'react-beautiful-dnd';

interface DragableItemWrapperProps {
    DraggableListItemComponent: JSX.Element;
    index: number;
    draggableId: string;
}

const DragableItemWrapper = ({
    DraggableListItemComponent,
    draggableId,
    index
}: DragableItemWrapperProps) => (
    <Draggable key={`key-[${draggableId}]`} draggableId={draggableId} index={index}>
        {provided => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {DraggableListItemComponent}
            </div>
        )}
    </Draggable>
);

export default DragableItemWrapper;

import { useEffect, useState } from 'react';
import { userService } from 'api/user.service';
import { UserDetailsDTO } from 'shared';
import { showToast } from 'utils/toast';
import DialogBody from 'components/DialogBody/DialogBody';
import { InputFilter } from 'components/InputFilter/InputFilter';
import { Button, Checkbox, GenericList, ScrollbarContainner } from 'components';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { DialogRes, DialogSaveRes } from './userListDialog.config';
import styles from './userListDialog.module.scss';

interface UsersListDialogProps {
    handleClose: (res: DialogRes) => void;
    userDeviceIds: number[];
}

const UsersListDialog = ({ handleClose, userDeviceIds }: UsersListDialogProps) => {
    const [users, setUsers] = useState<null | UserDetailsDTO[]>(null);
    const [filterUsers, setFilterUsers] = useState<string>('');
    const [selectedUserIdsSet, setSelectedUserIdsSet] = useState<Set<number>>(
        new Set(userDeviceIds)
    );

    useEffect(() => {
        userService
            .getUsersDetails()
            .then(usersData => {
                setUsers(usersData);
            })
            .catch(err => {
                console.log(err);
                // alert('Fetch all user failed!');
                showToast('Fetch all user failed!', 'error');
            });
    }, []);

    const save = (): DialogSaveRes => {
        return {
            actionCode: 'Save',
            selectedUsers: users.filter(user => selectedUserIdsSet.has(user.id))
        };
    };

    const checkHandler = (userId: number, checked: boolean) => {
        setSelectedUserIdsSet(set => {
            if (checked) {
                return new Set(set.add(userId));
            }
            set.delete(userId);
            return new Set(set);
        });
    };

    const selectAll = () => {
        setSelectedUserIdsSet(new Set(users.map(user => user.id)));
    };

    const usersList = users
        ?.filter(user => user.email.toLowerCase().includes(filterUsers.toLowerCase()))
        .map?.<GenericListItem>(user => {
            const isChecked = Boolean(selectedUserIdsSet.has(user.id));
            return {
                id: user.id,
                onClick: () => checkHandler(user.id, !isChecked),
                columns: [
                    [
                        {
                            type: 'avatarImg',
                            avatarImg: user.email.split('@')[0]
                        },
                        {
                            type: 'title',
                            title: user.email,
                            subTitle: user.roleName
                        }
                    ],
                    [
                        {
                            type: 'component',
                            component: (
                                <Checkbox
                                    checked={isChecked}
                                    handleChange={(_event, checked) => {
                                        checkHandler(user.id, checked);
                                    }}
                                />
                            )
                        }
                    ]
                ]
            };
        });

    return (
        <DialogBody
            header={
                <>
                    <div className={styles.titleContainer}>
                        <span className={styles.title}>הוספת משתמשים</span>
                        <span className={styles.selectedCount}>
                            סך הכל {selectedUserIdsSet?.size} נבחרו
                        </span>
                    </div>
                    <Button variant="text" isActive={false} onClick={selectAll}>
                        בחר הכל
                    </Button>
                </>
            }
            footer={
                <>
                    <Button onClick={() => handleClose(save())}>שמירה</Button>
                    <Button
                        variant="text"
                        isActive={false}
                        onClick={() => {
                            if (window.confirm('האם ברצונך לבטל את השינויים?')) {
                                handleClose({ actionCode: 'Cancel' });
                            }
                        }}>
                        ביטול
                    </Button>
                </>
            }>
            <InputFilter
                filter={filterUsers}
                setFilter={setFilterUsers}
                placeholder="חיפוש משתתפים"
            />
            <ScrollbarContainner className={styles.genericListContainer}>
                <GenericList listItems={usersList} />
            </ScrollbarContainner>
        </DialogBody>
    );
};

export default UsersListDialog;

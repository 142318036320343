import { FC } from 'react';
import { ComponentGuard } from 'auth/ComponentGuard';
import { groups } from 'auth/authConfig';
import Profile from '../Profile/Profile';
import SideMenuControllers from '../SideMenuControllers/SideMenuControllers';
import styles from './infoSection.module.scss';

interface InfoSectionProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

const InfoSection: FC<InfoSectionProps> = ({ handleDrawerToggle, open }) => {
    return (
        <div className={styles.infoSectionContainer}>
            <ComponentGuard requriedGroups={[groups.groupAdmin]}>
                <SideMenuControllers
                    open={open}
                    handleDrawerToggle={handleDrawerToggle}
                />
            </ComponentGuard>
            <Profile open={open} />
        </div>
    );
};

export default InfoSection;

import { styled, Typography } from '@mui/material';
import { useQuickTranslate } from 'quickode.infrastructure.react/translation';

export const NoDataContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: 'calc(100vh - 295px)',
    width: '100%',
    borderRadius: '5px',
    padding: '10px'
});

export const QuickMasterDetailNoData = () => {
    const { translate } = useQuickTranslate<any>();

    return (
        <NoDataContentWrapper>
            <Typography sx={{ fontSize: '1.5vmax' }}>
                {translate('quickTable', 'noMasterDetailData')}
            </Typography>
        </NoDataContentWrapper>
    );
};

import { Button } from '@mui/material';
import { groupService } from 'api';
import DialogBody from 'components/DialogBody/DialogBody';
import { GenericList } from 'components/GenericList/GenericList';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { InputFilter } from 'components/InputFilter/InputFilter';
import ScrollbarContainner from 'components/ScrollbarContainner/ScrollbarContainner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserDevices } from 'store';
import TabMenu from '../TabMenu/TabMenu';
import { DialogRes } from './userAdminDialog.config';

import styles from './userAdminDialog.module.scss';

interface UserAdminDialogProps {
    handleClose: (res: DialogRes) => void;
}

const UserAdminDialog = ({ handleClose }: UserAdminDialogProps) => {
    const userDevices = useSelector(getUserDevices);
    const [groups, setGroups] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedTab, setSelectedTab] = useState({ title: 'מכשיר', value: 0 });
    const [listItems, setListItems] = useState([]);

    useEffect(() => {
        groupService
            .getUserGroups()
            .then(data => {
                setGroups(data);
            })
            .catch(err => {
                console.log(err);
            });

        setListItems(
            userDevices
                .filter(
                    userDevice =>
                        userDevice.neme
                            ?.toLowerCase()
                            .includes(searchFilter.toLowerCase()) && userDevice.status
                )
                .map<GenericListItem>(userDevice => ({
                    id: userDevice.id,
                    onClick: () => handleClose({ actionCode: 'Save', userDevice }),
                    columns: [
                        [
                            {
                                type: 'title',
                                title: userDevice.neme,
                                subTitle: ''
                            }
                        ]
                    ]
                }))
        );
    }, []);

    const onTabChange = () => {
        if (selectedTab.value) {
            setListItems(
                groups
                    .filter(group =>
                        group.group_name
                            ?.toLowerCase()
                            .includes(searchFilter.toLowerCase())
                    )
                    .map<GenericListItem>(group => ({
                        id: group.id,
                        onClick: () => handleClose({ actionCode: 'Save', group }),
                        columns: [
                            [
                                {
                                    type: 'title',
                                    title: group.group_name,
                                    subTitle: group.group_desc
                                }
                            ]
                        ]
                    }))
            );
        } else {
            setListItems(
                userDevices
                    .filter(
                        userDevice =>
                            userDevice.neme
                                ?.toLowerCase()
                                .includes(searchFilter.toLowerCase()) && userDevice.status
                    )
                    .map<GenericListItem>(userDevice => ({
                        id: userDevice.id,
                        onClick: () => handleClose({ actionCode: 'Save', userDevice }),
                        columns: [
                            [
                                {
                                    type: 'title',
                                    title: userDevice.neme,
                                    subTitle: ''
                                }
                            ]
                        ]
                    }))
            );
        }
    };

    useEffect(() => {
        onTabChange();
    }, [searchFilter]);

    return (
        <DialogBody
            header={<TabMenu selectedTab={selectedTab} onTabChange={onTabChange} />}
            footer={
                <Button onClick={() => handleClose({ actionCode: 'Cancel' })}>
                    ביטול
                </Button>
            }>
            <span className={styles.title}>בחירת {selectedTab.title}</span>
            <InputFilter
                filter={searchFilter}
                setFilter={setSearchFilter}
                placeholder={'חיפוש ' + selectedTab.title}
            />
            <ScrollbarContainner>
                <GenericList listItems={listItems} />
            </ScrollbarContainner>
        </DialogBody>
    );
};

export default UserAdminDialog;

import PeopleIcon from '@mui/icons-material/People';
import { IRoute, GeneralNavRoutes, GeneralRoutes, RoleEnum, SideMenuRoute } from 'shared';
import { colors } from 'layout';
import { createAppTranslationKey } from 'translation';
import { Login, Logout } from 'pages/Auth';
import { Main, Channels, Devices, Users, Messages, Groups } from 'pages';

const routes: GeneralRoutes = {
    [GeneralNavRoutes.Login]: {
        layout: 'auth',
        renderSource: { componentToRender: Login }
    },
    [GeneralNavRoutes.Logout]: {
        layout: 'auth',
        renderSource: { componentToRender: Logout }
    },
    [GeneralNavRoutes.Main]: {
        //        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Main },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'main')
        }
    },
    [GeneralNavRoutes.Channels]: {
        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Channels },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'channels')
        }
    },
    [GeneralNavRoutes.Groups]: {
        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Groups },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'groups')
        }
    },
    [GeneralNavRoutes.Devices]: {
        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Devices },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'devices')
        }
    },
    [GeneralNavRoutes.Users]: {
        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Users },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'users')
        }
    },
    [GeneralNavRoutes.Messages]: {
        authRoles: [RoleEnum.Admin],
        renderSource: { componentToRender: Messages },
        sideMenuProps: {
            icon: <PeopleIcon sx={{ color: colors.black }} />,
            translationKey: createAppTranslationKey('sideNav', 'messages')
        }
    }
};

export const applicationRoutes = Object.entries(routes).reduce(
    (prev, [route, info]) => {
        const appRoute: IRoute = {
            authRoles: info.authRoles,
            layout: info.layout,
            renderSource: info.renderSource
        };
        if (info.layout === 'auth') {
            prev.auth[route] = appRoute;
        } else {
            prev.main[route] = appRoute;
        }

        return prev;
    },
    { auth: {}, main: {} } as {
        auth: { [key: string]: IRoute };
        main: { [key: string]: IRoute };
    }
);

export const sideMenuRoutes = Object.entries(routes).reduce((prev, [route, info]) => {
    if (info.sideMenuProps) {
        const sideMenuRoute: SideMenuRoute = {
            ...info.sideMenuProps,
            path: route,
            authRoles: info.authRoles
        };

        prev[route] = sideMenuRoute;
    }
    return prev;
}, {} as { [key: string]: SideMenuRoute });

export const createTypedTranslationKey =
    <TRoot>() =>
    <
        TRootKey extends keyof TRoot,
        TSecondKey extends keyof TRoot[TRootKey],
        TThirdKey extends TRoot[TRootKey][TSecondKey]
    >(
        rootKey: TRootKey,
        secondKey: TSecondKey,
        thirdKey?: keyof Omit<TThirdKey, keyof string>
    ) =>
        createTranslationKey(
            String(rootKey),
            String(secondKey),
            thirdKey && String(thirdKey)
        );

export const createTranslationKey = (
    key: string,
    secondaryKey: string,
    thirdKey?: string
): string => {
    return thirdKey
        ? `${String(key)}.${String(secondaryKey)}.${String(thirdKey)}`
        : `${String(key)}.${String(secondaryKey)}`;
};

export const quickTranslateSimple = (
    translations: any,
    key: string,
    config: { [key: string]: string } = {}
): string => {
    if (!key || key.length === 0) {
        return '';
    }

    const words = key.split('.');

    let currentKey: any = translations;

    for (const word of words) {
        currentKey = currentKey[word];

        if (!currentKey) {
            return key;
        }
    }

    if (Object.keys(config).length) {
        Object.keys(config).forEach(innerKey => {
            currentKey = currentKey.replace(`{${innerKey}}`, config[innerKey]);
        });
    }

    if (typeof currentKey === 'string') {
        return currentKey;
    }

    if (typeof currentKey === 'number') {
        return currentKey.toString();
    }

    return key;
};

export const quickTranslate = <
    TTranslations,
    TRootKey extends keyof TTranslations,
    TSecondKey extends keyof TTranslations[TRootKey],
    TThirdKey extends TTranslations[TRootKey][TSecondKey]
>(
    translations: TTranslations,
    rootKey: TRootKey,
    secondKey: TSecondKey,
    thirdKey?: keyof Omit<TThirdKey, keyof string>,
    config?: { [key: string]: string }
) => {
    const translationKey = createTranslationKey(
        String(rootKey),
        String(secondKey),
        thirdKey && String(thirdKey)
    );

    return quickTranslateSimple(translations, translationKey, config);
};

import { ReactNode } from 'react';
import {
    Avatar,
    List as MuiList,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@mui/material';
import classNames from 'classnames';
import { ReactComponent as ListItemSelectedRec } from 'assets/list/ListItemSelectedRec.svg';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DragableWrapper, DragableItemWrapper } from 'components/DragableWrapper';
import styles from './list.module.scss';

export interface DragableListObj {
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

export interface ListItemType {
    id: number;
    title: string;
    subTitle: string;
    img?: string;
    avatarImg?: string;
    component?: ReactNode;
    onClick?: () => void;
}

interface ListProps<T extends ListItemType> {
    listItems: T[];
    selectedItemId?: number;
    dragableListObj?: DragableListObj;
}

/**
 * The component is deprecated!
 * Use the GenericList component.
 */
export const List = <TListItem extends ListItemType>({
    listItems,
    selectedItemId,
    dragableListObj
}: ListProps<TListItem>) => {
    const listItemComponents = listItems.map((listItem, index) => {
        const selectedItem = selectedItemId === listItem.id;
        const listItemComponent = (
            <div key={listItem.id} className={styles.listItemContainer}>
                {selectedItem && <ListItemSelectedRec />}
                <ListItem
                    key={listItem.id}
                    className={classNames({
                        [styles.listItem]: true,
                        [styles.selectedListItem]: selectedItem
                    })}
                    onClick={() => {
                        listItem.onClick();
                    }}>
                    <div className={styles.listItemRight}>
                        {listItem.avatarImg && (
                            <ListItemAvatar>
                                <Avatar
                                    alt={listItem.title}
                                    // src={listItem.avatarImg}
                                />
                            </ListItemAvatar>
                        )}
                        <ListItemText
                            classes={{
                                root: styles.titleContainer,
                                primary: styles.title,
                                secondary: styles.subTitle
                            }}
                            primary={listItem.title}
                            secondary={listItem.subTitle}
                        />
                    </div>
                    <div className={styles.listItemLeft}>
                        {listItem?.img && (
                            <img
                                className={styles.img}
                                alt={listItem.title}
                                src={listItem.img}
                            />
                        )}
                        {listItem?.component && listItem.component}
                    </div>
                </ListItem>
            </div>
        );

        return dragableListObj ? (
            <DragableItemWrapper
                index={index}
                draggableId={listItem.id.toString()}
                DraggableListItemComponent={listItemComponent}
            />
        ) : (
            listItemComponent
        );
    });

    const listComponent = <MuiList className={styles.list}>{listItemComponents}</MuiList>;

    return (
        <div className={styles.listContainer}>
            {dragableListObj ? (
                <DragableWrapper
                    onDragEnd={dragableListObj.onDragEnd}
                    DraggableListItemsComponent={listComponent}
                />
            ) : (
                listComponent
            )}
        </div>
    );
};

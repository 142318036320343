import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { MessageDTO } from 'shared/models/message';
import { appApiProvider } from './axiosApiProvider';

export class MessageService extends QuickApiEntityService<MessageDTO> {
    constructor() {
        super(
            new QuickConsoleLogger('MessagesService'),
            appApiProvider,
            'messages',
            'Message'
        );
    }

    public async setHasReaded(messageId: number): Promise<MessageDTO> {
        return this.postOrThrow({ messageId }, { url: '/setHasReaded' });
    }

    public async getUserMessages(): Promise<MessageDTO[]> {
        return this.getOrThrow({ url: '/getUserMessages' });
    }
}

export const messageService = new MessageService();

import { FormHelperText } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { Fields } from '../../formTypes';
import styled from './validationMessage.module.scss';

interface ValidationMessageProps {
    fieldValue: Fields;
    error?: FieldError;
}

const getValidationMessage = ({ options }: Fields, error: FieldError) => {
    if (error.message) return error.message;
    switch (error.type) {
        case 'required':
            return `שדה חובה`;
        case 'maxLength':
            return `מקסימום ${options.maxLength} תווים`;
        default:
            return 'לא חוקי';
    }
};

const ValidationMessage = ({ fieldValue, error }: ValidationMessageProps) =>
    error && (
        <FormHelperText className={styled.validationMessage}>
            {getValidationMessage(fieldValue, error)}
        </FormHelperText>
    );

export default ValidationMessage;

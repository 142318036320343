import { Autocomplete, Checkbox, FormHelperText, Chip, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useFormStyles } from '../quick-form-styles.hook';
import { QFFieldSelectMultiple, QFormSelectValue } from '../quick-form.model';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { KeyboardEvent, useState } from 'react';

export interface QuickSelectMultipleProps extends Omit<QFFieldSelectMultiple, 'type'> {
    name: string;
    label: string;
    placeholder?: string;
    style?: React.CSSProperties;
    control: Control<FieldValues, any>;
    getOptionDisabled?: (option: any) => boolean;
    disableClearable?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const QuickSelectMultiple = ({
    name,
    label,
    placeholder,
    readOnly,
    values,
    style,
    control,
    required,
    disableClearable,
    getOptionDisabled
}: QuickSelectMultipleProps) => {
    const { sectionStyle } = useFormStyles(name, style);

    const [inputValue, setInputValue] = useState('');

    const valuesMap = values
        ? values.reduce(
              (
                  previousValue: Record<string, string>,
                  currentValue: QFormSelectValue
              ) => ({
                  ...previousValue,
                  [currentValue.value]: currentValue.text
              }),
              {} as Record<string, string>
          )
        : {};

    return (
        <section key={`section-${name}`} style={sectionStyle}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <>
                            <Autocomplete
                                sx={{ width: 'inherit!important' }}
                                multiple
                                disabled={readOnly}
                                options={Object.keys(valuesMap)}
                                value={value}
                                onChange={(_event: any, newValue: string[]) => {
                                    onChange(newValue);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={valuesMap[option]}
                                            {...getTagProps({ index })}
                                            disabled={getOptionDisabled?.(option)}
                                        />
                                    ))
                                }
                                disableClearable={disableClearable}
                                getOptionDisabled={getOptionDisabled}
                                disableCloseOnSelect
                                getOptionLabel={value => valuesMap[value]}
                                renderOption={(props, value, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {valuesMap[value]}
                                    </li>
                                )}
                                style={{ width: 500 }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        onKeyDown={(
                                            event: KeyboardEvent<HTMLInputElement>
                                        ) => {
                                            if (event.key === 'Backspace') {
                                                event.stopPropagation();
                                            }

                                            params.inputProps?.onKeyDown?.(event);
                                        }}
                                        label={label}
                                        placeholder={label}
                                        required={required}
                                    />
                                )}
                            />
                            {error ? (
                                <FormHelperText error>{error?.message}</FormHelperText>
                            ) : null}
                        </>
                    );
                }}
            />
        </section>
    );
};

export enum QuickMessageBoxType {
    Error = 'error',
    Success = 'success',
    Question = 'question',
    SimpleAlert = 'simpleAlert'
}
export type QuickMessageInfoType =
    | QuickMessageBoxType.Error
    | QuickMessageBoxType.Success;
export type QuickMessageBoxButton = { text: string; buttonResult: any };
export const QuickMessageBoxCancel = '__QUICK_MESSAGE_BOX_CANCEL__';

export type QuickMessageBoxItem = {
    type: QuickMessageBoxType;
    content: string | JSX.Element;
    buttons: QuickMessageBoxButton[];
    onUserClick: (result: any) => void;
    title?: string;
    allowCancel?: boolean;
};

export interface QuickMessageBoxItemState {
    quickMessageBox: QuickMessageBoxItem;
}

import { Dispatch, SetStateAction } from 'react';
import { ToggleButton, ToggleButtonGroup as ToggleButtonGroupMUI } from '@mui/material';
import styles from './toggleButtonGroup.module.scss';

interface ToggleButtonGroupProps<T> {
    value: T;
    onChangeHandler: Dispatch<SetStateAction<T>>;
    items: { value: T; description: string }[];
}

const ToggleButtonGroup = <T extends unknown>({
    value,
    onChangeHandler,
    items
}: ToggleButtonGroupProps<T>) => {
    return (
        <ToggleButtonGroupMUI
            color="primary"
            value={value}
            exclusive
            onChange={(_event, toggleValue) => onChangeHandler(toggleValue)}
            classes={{ root: styles.toggleButtonGroup }}>
            {items.map(item => (
                <ToggleButton
                    classes={{
                        root: styles.toggleButton,
                        selected: styles.selectedToggleButton
                    }}
                    value={item.value}>
                    {item.description}
                </ToggleButton>
            ))}
        </ToggleButtonGroupMUI>
    );
};

export default ToggleButtonGroup;

import EntityList from 'components/EntityList/EntityList';
import { DragableListObj, ListItemType } from 'components/List/List';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { EntitiesPageLayout } from 'layout';
import { EntitiesPageLayoutProps } from 'layout/layouts/EntitiesPageLayout/EntitiesPageLayout';
import { Dispatch, SetStateAction } from 'react';

interface BaseEntityProps<T> {
    creatable?: boolean;
    singularEntityName: string;
    pluralEntityName: string;
    selectedEntity: T;
    updateSelectedEntity: (entity?: T | null) => void;
    filteredEntityListItems: ListItemType[];
    entityFilter: string;
    setEntityFilter: Dispatch<SetStateAction<string>>;
    dragableListObj?: DragableListObj;
    listActionComponent?: JSX.Element;
}

type EntityProps<T> = BaseEntityProps<T> & EntitiesPageLayoutProps<T>;

/**
 * @component The main wrapper for Entity.
 *
 * @prop {boolean} creatableIs Is the form createable?
 * @prop {string} singularEntityName The name of the entity as singular.
 * @prop {string} pluralEntityName The name of the entity as plural.
 * @prop {MappingFieldsType<string>} entityFields Form configuration object.
 * @prop {AdditionalSectionsType<string>[]} entityAdditionalSections Form configuration object.
 * @prop {T} selectedEntity The selected item entity (will use for edit mode).
 * @prop {Record<string, string | number>} entityFieldValues initial values for form (will use for edit mode).
 * @prop {(entity: T) => void} onDelete The form delete function.
 * @prop {(entity: T) => void} onSubmit The form submission function.
 * @prop {(entity?: T | null) => void} updateSelectedEntity Function to update the selected entity.
 * @prop {ListItemType[]} filteredEntityListItems List of filtered entities.
 * @prop {string} entityFilter The filter value.
 * @prop {Dispatch<SetStateAction<string>>} setEntityFilter The setter for filter value.
 */
const Entity = <T extends { id?: number }>({
    creatable,
    singularEntityName,
    pluralEntityName,
    selectedEntity,
    updateSelectedEntity,
    filteredEntityListItems,
    entityFilter,
    setEntityFilter,
    dragableListObj,
    listActionComponent,
    ...props
}: EntityProps<T>) => {
    useDisplayMode();

    return (
        <EntitiesPageLayout
            {...props}
            singularEntityName={singularEntityName}
            selectedEntity={selectedEntity}>
            <EntityList<ListItemType, T>
                creatable={creatable}
                entitiesListItems={filteredEntityListItems}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                selectedEntity={selectedEntity}
                updateSelectedEntity={() => updateSelectedEntity(null)}
                filter={entityFilter}
                setFilter={setEntityFilter}
                dragableListObj={dragableListObj}
                listActionComponent={listActionComponent}
            />
        </EntitiesPageLayout>
    );
};

export default Entity;

import { FC, PropsWithChildren } from 'react';
import styles from './dialogBody.module.scss';

interface DialogBodyProps extends PropsWithChildren {
    header: JSX.Element;
    footer: JSX.Element;
}

const DialogBody: FC<DialogBodyProps> = ({ header, footer, children }) => {
    return (
        <div className={styles.dialogBody}>
            <div className={styles.header}>{header}</div>
            {children}
            <div className={styles.footer}>{footer}</div>
        </div>
    );
};

export default DialogBody;

import {
    createQuickNavigator,
    QuickNavigatorHistoryProvider
} from 'quickode.infrastructure.react/navigation';
import { AppNavParams } from 'shared';
import { historyInstance } from './history';

const historyProvider: QuickNavigatorHistoryProvider = {
    navigate(pageName, params) {
        historyInstance.push(pageName, params);
        window.location.reload();
    },

    goBack() {
        historyInstance.back();
    },

    replace(pageName, params) {
        historyInstance.replace(pageName, params);
    }
};

export const appNavigator = createQuickNavigator<AppNavParams>(historyProvider);

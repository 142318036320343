import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: 'c25a34f5-0d96-46eb-8886-bd3d9e5a1349',
        authority:
            'https://login.microsoftonline.com/5e4e3e1f-c501-418e-9038-e83cf4663848',
        redirectUri: '/auth/login',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            }
        }
    }
};

export const protectedResources = {
    iptvApi: {
        scopes: [`${msalConfig.auth.clientId}/.default`]
    }
};

export const groups = {
    groupAdmin: '60d7f843-f705-4e8e-83b4-ccda39a739b2'
};

export const loginRequest: RedirectRequest = {
    scopes: [...protectedResources.iptvApi.scopes]
};

import { FC } from 'react';
import { RegisterOptions, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { PartialRecord } from 'shared/models/utilsModels';
import { ButtonProps, ListItemType } from 'components';
import { ColumnItem } from '../GenericList/genericList.config';

export enum FieldType {
    INPUT = 'input',
    SELECT = 'select',
    SWITCH = 'switch',
    UPLOAD_IMG = 'uploadImage',
    LIST = 'list',
    GENERIC_LIST = 'genericList',
    Title = 'title',
    String = 'string'
}

export enum ActionType {
    BUTTOM = 'buttonAction'
}

export type BaseField = {
    type: FieldType;
    description: string;
    options?: RegisterOptions;
};

export interface ActionField {
    type: ActionType;
}

export interface ButtonFieldAction extends ActionField, Pick<ButtonProps, 'variant'> {
    type: ActionType.BUTTOM;
    text: string;
    onClick: () => void;
}

export interface InputField extends BaseField {
    type: FieldType.INPUT;
}
export interface SelectField extends BaseField {
    type: FieldType.SELECT;
    optionItems: { value: string | number; desctiption: string }[];
}

export interface SwitchField extends BaseField {
    type: FieldType.SWITCH;
    subDescription: string | ((isActive: boolean) => string);
}

export interface UploadImageField extends BaseField {
    type: FieldType.UPLOAD_IMG;
}

export interface ListField extends BaseField {
    type: FieldType.LIST;
    listItem: ListItemType[];
}
export interface ListFormField extends BaseField {
    type: FieldType.GENERIC_LIST;
    listItem: (
        userDevice: any,
        setFormValue: (newValue: any[]) => void,
        userDevicesForm: any[]
    ) => ColumnItem[][];
}

export interface TitleField extends BaseField {
    type: FieldType.Title;
    value: string;
}

export interface StringField extends BaseField {
    type: FieldType.String;
    value: string;
}

// The fields that will affect the submission form.
// Interfaced with react-hook-form package.
export type Fields =
    | InputField
    | SelectField
    | SwitchField
    | UploadImageField
    | ListField
    | ListFormField
    | TitleField
    | StringField;

// The "fields" that will affect external events. e.g. reset pi button in the edit device form.
export type ActionFields = ButtonFieldAction;

export type FieldsType = Fields | ActionFields;

export type MappingFieldsType<TFields extends string> = PartialRecord<
    TFields,
    FieldsType
>;

interface SectionType<TFields extends string> {
    title: string;
    subTitle?: string | ((watch: UseFormWatch<Record<TFields, any | any[]>>) => string);
    fields: MappingFieldsType<TFields>;
}

export interface MainSectionsType<TFields extends string> extends SectionType<TFields> {
    actionsComponent?: JSX.Element;
}

export interface AdditionalSectionsType<TFields extends string>
    extends SectionType<TFields> {
    ActionsComponent?: FC<{ setValue: UseFormSetValue<any> }>;
}

export type Section<TFields extends string> = MainSectionsType<TFields> &
    AdditionalSectionsType<TFields>;

export interface ReactHookFormProps<TFields extends string> {
    entityFields: MappingFieldsType<TFields>;
}

import { FieldType, MappingFieldsType } from 'components/Form/formTypes';
import { Category, Channel, ChannelModule } from 'shared';
import Kan11Logo from 'assets/channels/kan11.png';

export type ChannelFields = keyof Channel;

export interface ChannelFormValues {
    channelNameHE: string;
    channelNameEN: string;
    address: string;
    moduleType: string;
    categoryId: number;
    channelLogo: string | File;
    channelLogoMobile: string | File;
}

export const getChannelFields = (
    categories: Category[] | null
): MappingFieldsType<ChannelFields> => ({
    channelNameHE: {
        type: FieldType.INPUT,
        description: 'שם ערוץ בעברית',
        options: {
            required: true,
            maxLength: 50
        }
    },
    channelNameEN: {
        type: FieldType.INPUT,
        description: 'שם ערוץ באנגלית',
        options: {
            required: true,
            maxLength: 50
        }
    },
    address: {
        type: FieldType.INPUT,
        description: 'כתובת',
        options: {
            required: true,
            maxLength: 100
        }
    },
    moduleType: {
        type: FieldType.SELECT,
        description: 'מודול',
        optionItems: [
            { value: ChannelModule.TV, desctiption: 'טלוויזיה' },
            { value: ChannelModule.RADIO, desctiption: 'רדיו' }
        ],
        options: {
            required: true
        }
    },
    categoryId: {
        type: FieldType.SELECT,
        description: "ז'אנר",
        optionItems:
            categories?.map(category => ({
                value: category.id,
                desctiption: category.name
            })) || [],
        options: {
            required: true
        }
    },
    channelLogo: {
        type: FieldType.UPLOAD_IMG,
        description: 'העלאת תמונה',
        options: {
            required: true
        }
    },
    channelLogoMobile: {
        type: FieldType.UPLOAD_IMG,
        description: 'העלאת תמונה mobile',
        options: {
            required: true
        }
    }
});

export const getChannelFieldsValues = (channel?: Channel): ChannelFormValues => ({
    channelNameHE: channel?.channelNameHE || '',
    channelNameEN: channel?.channelNameEN || '',
    address: channel?.address || '',
    moduleType: channel?.moduleType || ChannelModule.TV,
    categoryId: channel?.categoryId,
    channelLogo: channel?.channelLogo || Kan11Logo,
    channelLogoMobile: channel?.channelLogoMobile || Kan11Logo
});

import { UseControllerProps, useController } from 'react-hook-form';

type TitleProps = UseControllerProps & {
    dataObj: { description: string; value: string };
    className?: string;
};

const Title = ({ dataObj, name, control, className }: TitleProps) => {
    return (
        <div className={className}>
            {dataObj.description}: {dataObj.value}
        </div>
    );
};

export default Title;

import { useEffect } from 'react';
import { categoryService, userService, authorityService, channelService } from 'api';
import { actions, getCurrentUser, getIsUserAuthenticated } from 'store';
import { useSelector } from 'react-redux';
import { ChannelModule } from 'shared';

export const useAppInitialization = () => {
    const isAuthenticated = useSelector(getIsUserAuthenticated);
    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        if (isAuthenticated && currentUser) {
            authorityService
                .getEntities()
                .then(data => {
                    actions.authorities.set(data);
                })
                .catch(err => {
                    console.log(
                        'Cannot fetch categories, exception has been thrown',
                        err
                    );
                });
            categoryService
                .getEntities()
                .then(data => {
                    actions.categories.set(data);
                })
                .catch(err => {
                    console.log(
                        'Cannot fetch categories, exception has been thrown',
                        err
                    );
                });

            userService
                .getUserDevices()
                .then(userDevices => {
                    actions.userDevices.set(userDevices);
                    actions.userSelections.partialUpdate({
                        device: userDevices.find(device => device.status)
                    });
                })
                .catch(err => {
                    console.log('Cannot fetch devices, exception has been thrown', err);
                });

            channelService
                .getEntities()
                .then(data => {
                    actions.userSelections.partialUpdate({
                        channel: data.find(
                            channel => channel.moduleType === ChannelModule.TV
                        )
                    });
                    actions.channels.set(data);
                })
                .catch(err => {
                    console.log('Cannot fetch channels, exception has been thrown', err);
                });
        }
    }, [isAuthenticated, currentUser]);
};

import { Section, FieldType, MappingFieldsType } from 'components/Form/formTypes';
import { AuthorityDTO, Device, GroupDTO, GroupFetchDTO, RelatedGroupUser } from 'shared';
import { IconButton } from '@mui/material';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import UserSectionActions from './components/UserSection/UserSectionActions';
import DeviceSectionActions from './components/DeviceSection/DeviceSectionActions';

export type GroupFields = keyof GroupDTO;

export interface GroupForm {
    id: number;
    group_name: string;
    group_desc: string;
    group_auth_level: number;
    devices: Device[];
    users: RelatedGroupUser[];
    radioAuth: boolean;
}

export const getGroupFields = (
    authorities: AuthorityDTO[]
): MappingFieldsType<GroupFields> => ({
    group_name: {
        type: FieldType.INPUT,
        description: 'שם הקבוצה',
        options: {
            required: true,
            maxLength: 50
        }
    },
    group_desc: {
        type: FieldType.INPUT,
        description: 'עוד מידע',
        options: {
            required: true,
            maxLength: 50
        }
    },
    group_auth_level: {
        type: FieldType.SELECT,
        description: 'רמת הרשאה',
        optionItems: authorities?.map((authority: AuthorityDTO) => ({
            value: authority.id,
            desctiption: authority.authDescription
        })),
        options: {
            required: true
        }
    },
    radioAuth: {
        type: FieldType.SWITCH,
        description: 'ערוץ רדיו מפוצל',
        subDescription: null
    }
});

export const getGroupFieldsValues = (group?: GroupFetchDTO): GroupForm => ({
    id: group?.id,
    group_name: group?.group_name || '',
    group_desc: group?.group_desc || '',
    group_auth_level: group?.group_auth_level || 1,
    devices: group?.devices || [],
    users: group?.users || [],
    radioAuth: group?.radioAuth || false
});

export const getGroupAdditionalSections = (
    groupSelected?: GroupFetchDTO
): Section<keyof GroupFetchDTO>[] => [
    {
        title: 'רשימת משתמשים',
        subTitle: getValues => {
            const { users } = getValues();
            return (users?.length || '0') + ' משתמשים';
        },
        ActionsComponent: ({ setValue }) => (
            <UserSectionActions
                keyForm="users"
                group={groupSelected}
                setValue={setValue}
            />
        ),
        fields: {
            users: {
                type: FieldType.GENERIC_LIST,
                description: '',
                listItem: (
                    userDevice: RelatedGroupUser,
                    setFormValue,
                    userDevicesForm
                ) => [
                    [
                        {
                            type: 'avatarImg',
                            avatarImg: ''
                        },
                        {
                            type: 'title',
                            title: userDevice.email,
                            subTitle: userDevice.roleName
                        }
                    ],
                    [
                        {
                            type: 'component',
                            component: (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setFormValue(
                                            userDevicesForm.filter(
                                                userDeviceForm =>
                                                    userDeviceForm.id !== userDevice.id
                                            )
                                        )
                                    }>
                                    <TrashIcon />
                                </IconButton>
                            )
                        }
                    ]
                ]
            }
        }
    },
    {
        title: 'רשימת מכשירים',
        subTitle: getValues => {
            const { devices } = getValues();
            return (devices?.length || '0') + ' מכשירים';
        },
        ActionsComponent: ({ setValue }) => (
            <DeviceSectionActions
                keyForm="devices"
                group={groupSelected}
                setValue={setValue}
            />
        ),
        fields: {
            devices: {
                type: FieldType.GENERIC_LIST,
                description: '',
                listItem: (device, setFormValue, groupDevicesForm) => [
                    [
                        {
                            type: 'title',
                            title: device.neme,
                            subTitle: undefined
                        }
                    ],
                    [
                        {
                            type: 'component',
                            component: (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setFormValue(
                                            groupDevicesForm.filter(
                                                groupDeviceForm =>
                                                    groupDeviceForm.id !== device.id
                                            )
                                        )
                                    }>
                                    <TrashIcon />
                                </IconButton>
                            )
                        }
                    ]
                ]
            }
        }
    }
];

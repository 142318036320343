import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToast = (
    message: string,
    type: 'info' | 'success' | 'error' | 'warning',
    expTime?: 5000
) => {
    toast[type](message, {
        position: 'top-center',
        autoClose: expTime
    });
};

import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { useDialog } from 'quickode.infrastructure.react';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RelatedUser } from 'shared';
import { getAuthorities } from 'store';
import { DialogRes } from '../UsersListDialog/userListDialog.config';
import UsersListDialog from '../UsersListDialog/UsersListDialog';

interface UserSectionActionsProps {
    keyForm: string;
    setValue: UseFormSetValue<any>;
}

const UserSectionActions = ({ keyForm, setValue }: UserSectionActionsProps) => {
    const { openDialog } = useDialog();
    const { watch } = useFormContext();
    const authorities = useSelector(getAuthorities);

    const openAddingUserDevicesDialog = async () => {
        const userDevices = watch().relatedUsers;
        const userDeviceIds = userDevices?.map((user: RelatedUser) => user.userId);
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return (
                    <UsersListDialog
                        handleClose={handleClose}
                        userDeviceIds={userDeviceIds}
                    />
                );
            }
        });

        if (res.actionCode === 'Cancel') {
            console.log('cancel!');
        } else if (res.actionCode === 'Save') {
            // setValue from useFormContext does not affect isDirty even with the shouldDirty option. link: https://github.com/react-hook-form/react-hook-form/issues/72#issuecomment-1096813727
            setValue(
                keyForm,
                [
                    ...userDevices,
                    ...res.selectedUsers.map(user => ({
                        id: user.id,
                        email: user.email,
                        img: user.img,
                        roleName: user.roleName,
                        userId: user.id,
                        authLevelId: authorities[0].id
                    }))
                ],
                {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true
                }
            );
        }
    };

    return (
        <IconButton
            sx={{ color: 'white' }}
            size="small"
            onClick={openAddingUserDevicesDialog}>
            <AddIcon />
        </IconButton>
    );
};

export default UserSectionActions;

import { useSelector } from 'react-redux';
import { ListItemType } from 'components';
import Entity from 'components/Entity/Entity';
import { GroupCUDTO, GroupFetchDTO } from 'shared';
import { useEffect, useState } from 'react';
import { groupService } from 'api';
import { getAuthorities, setDisplayMode } from 'store';
import { getIsTextFiltered } from 'utils/filter';
import { showToast } from 'utils/toast';
import {
    getGroupAdditionalSections,
    getGroupFields,
    getGroupFieldsValues,
    GroupForm
} from './group.config';

const Groups = () => {
    const [groups, setGroups] = useState<GroupFetchDTO[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupFetchDTO | null>(null);
    const [groupFieldValues, setGroupFieldsValues] = useState<Partial<GroupForm>>(
        getGroupFieldsValues()
    );
    const [groupFilter, setGroupFilter] = useState<string>('');
    const authorities = useSelector(getAuthorities);

    const fetchAllData = (options?: { noSetSelectedChannel: boolean }) => {
        groupService
            .getEntities()
            .then(data => {
                setGroups(data);
                if (!options?.noSetSelectedChannel) {
                    setSelectedGroup(data[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setGroupFieldsValues(getGroupFieldsValues(selectedGroup));
    }, [selectedGroup]);

    const updateSelectedEntity = (device?: GroupFetchDTO | null) => {
        setSelectedGroup(device);
    };

    const onSubmit = (group?: GroupFetchDTO) => {
        const groupForBodyReq: GroupCUDTO = {
            ...group,
            relatedUsersIds: group.users?.map(user => user.userId),
            relatedDevicesIds: group.devices?.map(device => device.id)
        };
        if (selectedGroup?.id) {
            console.log('Group editing');
            groupService
                .update(groupForBodyReq)
                .then(resData => {
                    // alert('The group update was successful!');
                    showToast('The group update was successful!', 'success');

                    console.log('The group update was successful ' + resData);
                    fetchAllData({ noSetSelectedChannel: true });
                })
                .catch(err => {
                    // alert('The group update failed with err: ' + err);
                    showToast('The group update failed with err: ' + err, 'error');
                    console.log('The group update failed with err: ' + err);
                });
        } else {
            groupService
                .create(groupForBodyReq)
                .then(data => {
                    // alert('The group create was successful!');
                    showToast('The group create was successful!', 'success');
                    console.log('The group create was successful ' + data);
                    fetchAllData();
                })
                .catch(err => {
                    // alert('The group create failed with err: ' + err);
                    showToast('The group create failed with err: ' + err, 'error');
                    console.log('The group create failed with err: ' + err);
                });
        }
    };

    const onDeleteHandler = () => {
        groupService
            .remove(selectedGroup)
            .then(() => {
                console.log(`The deletion of group ${selectedGroup.id} was successful!`);
                // alert(`The deletion of group ${selectedGroup.id} was successful!`);
                showToast(
                    `The deletion of group ${selectedGroup.id} was successful!`,
                    'success'
                );

                fetchAllData();
            })
            .catch(err => {
                console.log(`The deletion of group ${selectedGroup.id} failed!`);
                // alert(`The deletion of group ${selectedGroup.id} failed!`);
                showToast(`The deletion of group ${selectedGroup.id} failed!`, err);
            });
    };

    const groupListItems: ListItemType[] = groups.map(group => ({
        id: group.id,
        title: group.group_name,
        subTitle: (group.devices?.length || 0) + ' מכשירים',
        onClick: () => {
            updateSelectedEntity(group);
            setDisplayMode('form');
        }
    }));

    const filteredGroupListItems = groupListItems.filter(group =>
        getIsTextFiltered(group.title?.toLowerCase(), groupFilter?.toLowerCase())
    );

    const groupFields = getGroupFields(authorities);
    const groupAdditionalSections = getGroupAdditionalSections(selectedGroup);
    return (
        <Entity
            type="form"
            singularEntityName="קבוצה"
            pluralEntityName="קבוצות"
            entityFields={groupFields}
            entityFieldValues={groupFieldValues}
            entityAdditionalSections={groupAdditionalSections}
            selectedEntity={selectedGroup}
            onSubmit={onSubmit}
            onDelete={onDeleteHandler}
            updateSelectedEntity={setSelectedGroup}
            filteredEntityListItems={filteredGroupListItems}
            entityFilter={groupFilter}
            setEntityFilter={setGroupFilter}
        />
    );
};

export default Groups;

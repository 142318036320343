import { FC } from 'react';
import { styled } from '@mui/system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';

interface Props {
    onCloseHandler: () => void;
    children: React.ReactNode;
    minWidth?: string;
}

const CloseButtonContainer = styled('div')({
    position: 'absolute',
    top: 6,
    right: 6
});

const MasterDetailContentWrapper = styled('div')({
    position: 'sticky',
    top: 0,
    right: 0,
    bottom: 0,

    backgroundColor: '#f2f3f6',

    padding: '10px',
    width: '50vw',
    minWidth: '500px'
});

export const QuickMasterDetailContainer: FC<Props> = ({
    children,
    onCloseHandler,
    minWidth
}) => {
    return (
        <MasterDetailContentWrapper style={{ minWidth }}>
            <CloseButtonContainer>
                <IconButton
                    onClick={onCloseHandler}
                    color="secondary"
                    aria-label="upload picture"
                    component="label">
                    <CloseRoundedIcon />
                </IconButton>
            </CloseButtonContainer>
            {children}
        </MasterDetailContentWrapper>
    );
};

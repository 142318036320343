export function b64toBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

export function b64toFile(dataURI: string) {
    const blob: Blob = b64toBlob(dataURI);
    return new File([blob], 'kan-11');
}

export const isImage = (fileType: string) => {
    const type = fileType.split('.')[0].split('/');
    return (
        type[0].startsWith('image') &&
        (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png')
    );
};

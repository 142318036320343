import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { AuthorityDTO } from 'shared';
import { appApiProvider } from './axiosApiProvider';

export class AuthorityService extends QuickApiEntityService<AuthorityDTO> {
    constructor() {
        super(
            new QuickConsoleLogger('AuthorityService'),
            appApiProvider,
            'authority',
            'Authority'
        );
    }
}

export const authorityService = new AuthorityService();

import { useCallback } from 'react';
import { createArrayState, QuickReduxDispatch } from '../state';
import { QuickStateArrayActions } from '../state/state-array/state-array-model';
import { Dialog } from './dialog.model';

let dialogActions: QuickStateArrayActions<Dialog>;
export type DialogProps = Omit<Dialog, 'handleClose' | 'key'>;

export const appendDialogState = (dispatch: QuickReduxDispatch) => {
    if (dialogActions) {
        throw new Error('Cannot initialize QuickDialog more than once');
    }

    const dialogs = createArrayState<Dialog>('dialogs', dispatch);

    dialogActions = dialogs.actions;

    return { dialogs };
};

export const useDialog = () => {
    const openDialog = useCallback(
        <T = any>({ render }: DialogProps) =>
            new Promise<T>(resolve => {
                const dialog: Dialog = {
                    key: performance.now(),
                    render,
                    handleClose: null
                };

                const handleClose = (result?: any) => {
                    dialogActions.remove(dialog);
                    resolve(result);
                };

                dialog.handleClose = handleClose;
                dialogActions.push(dialog);
            }),
        []
    );

    return { openDialog };
};

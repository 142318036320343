import { FormControl, InputBase, InputLabel, MenuItem } from '@mui/material';
import { Button } from 'components/Button/Button';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormProps } from './formTypes';
import {
    List,
    Select,
    Switch,
    Title,
    UploadFileButton,
    ValidationMessage
} from './components';
import styles from './form.module.scss';

/**
 * @component Form items creation
 */
const Form = ({ entityFields }: ReactHookFormProps<string>) => {
    const {
        register,
        formState: { errors },
        control,
        setError
    } = useFormContext<
        Record<keyof typeof entityFields, string | number | boolean | number[]>
    >();

    return (
        <>
            {Object.entries(entityFields).map(([key, dataObj]) => {
                switch (dataObj.type) {
                    case 'input':
                        return (
                            <FormControl
                                key={key}
                                variant="standard"
                                classes={{ root: styles.formControl }}>
                                <InputLabel shrink htmlFor={key}>
                                    {dataObj.description}
                                </InputLabel>
                                <InputBase
                                    id={key}
                                    {...register(key, {
                                        ...dataObj.options,
                                        validate: {
                                            nonEmptyString: value =>
                                                !!value.toString().trim()
                                        },
                                        ...(typeof dataObj.options.validate === 'function'
                                            ? {
                                                  externalValidation:
                                                      dataObj.options.validate
                                              }
                                            : dataObj.options.validate)
                                    })}
                                />
                                <ValidationMessage
                                    fieldValue={dataObj}
                                    error={errors[key]}
                                />
                            </FormControl>
                        );
                    case 'select':
                        return (
                            <Select
                                key={key}
                                name={key}
                                control={control}
                                dataObj={dataObj}
                                rootClass={styles.formControl}>
                                {dataObj.optionItems.map(optionItem => (
                                    <MenuItem
                                        key={optionItem.value}
                                        value={optionItem.value}>
                                        {optionItem.desctiption}
                                    </MenuItem>
                                ))}
                            </Select>
                        );
                    case 'switch':
                        return (
                            <Switch
                                key={key}
                                className={styles.formControl}
                                dataObj={dataObj}
                                name={key}
                                control={control}
                            />
                        );
                    case 'uploadImage':
                        return (
                            <FormControl
                                key={key}
                                variant="standard"
                                classes={{ root: styles.formControl }}>
                                <UploadFileButton
                                    text={dataObj.description}
                                    name={key}
                                    control={control}
                                    setError={setError}
                                />
                                <ValidationMessage
                                    fieldValue={dataObj}
                                    error={errors[key]}
                                />
                            </FormControl>
                        );
                    case 'genericList':
                        return (
                            <List
                                name={key}
                                key={key}
                                control={control}
                                dataObj={dataObj}
                            />
                        );
                    case 'buttonAction':
                        return (
                            <Button
                                key={key}
                                className={styles.formBtn}
                                onClick={dataObj.onClick}
                                variant={dataObj.variant}>
                                {dataObj.text}
                            </Button>
                        );
                    case 'title':
                        return (
                            <Title
                                key={key}
                                name="title"
                                className={styles.title}
                                dataObj={dataObj}
                            />
                        );
                    case 'string':
                        return (
                            <Title
                                key={key}
                                name="title"
                                className={styles.string}
                                dataObj={dataObj}
                            />
                        );
                    default:
                        return <div key={key}>field</div>;
                }
            })}
        </>
    );
};

export default Form;

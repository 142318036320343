import { AuthenticationResult } from '@azure/msal-browser';
import { loginRequest } from 'auth/authConfig';
import { appConfig } from 'config';

import { RoleEnum } from 'shared';
import { actions } from 'store';
import { authService } from 'api';
import { msalInstance } from '../index';

export const useAuth = () => {
    const getToken = async (): Promise<AuthenticationResult> => {
        return msalInstance.acquireTokenSilent({ ...loginRequest });
    };

    const loginUser = async (): Promise<string> => {
        await msalInstance.handleRedirectPromise();
        const account = msalInstance.getActiveAccount();
        if (!account) {
            await msalInstance.loginRedirect({
                ...loginRequest
            });
        }

        if (appConfig.useMockData) {
            actions.user.set({
                email: account.username,
                firstName: account.name.split(' ')[0],
                lastName: account.name.split(' ')[1],
                id: 1,
                role: RoleEnum.ResearchAndDevelopment,
                img: null
            });
        } else {
            const userData = await authService.login();
            actions.user.set(userData.user);
        }

        return null;
    };

    // const refreshUserProfile = async () => {
    //     const currentUser = await userService.getCurrentUserProfile();

    //     console.log('Refresh current user profile', currentUser);

    //     actions.user.set(currentUser);

    //     return currentUser;
    // };

    const logoutUser = async (): Promise<string> => {
        if (msalInstance.getActiveAccount()) {
            await msalInstance.logoutRedirect();
        }

        actions.user.clear();

        actions.accessToken.clear();

        return null;
    };

    return {
        // refreshUserProfile,
        loginUser,
        getToken,
        logoutUser
    };
};

import { Button } from '@mui/material';
import { useTranslate } from 'hooks';
import { MessageDTO } from 'shared';
import { createAppTranslationKey } from 'translation';
import styles from './popupmessage.module.scss';

interface PopUpMessageProps {
    message: MessageDTO;
    index: number;
    setReaded: (id: number, index: number) => void;
}

export const PopUpMessage = ({ message, setReaded, index }: PopUpMessageProps) => {
    const { simpleTranslate } = useTranslate();

    const changeReaded = () => {
        setReaded(message.id, index);
    };

    return (
        <div className={`${styles.popupmessage}`}>
            <div className={`${styles.box}`}>{message.description}</div>
            <hr />
            <div className={`${styles.button}`}>
                <Button className={`${styles.button}`} onClick={changeReaded}>
                    {simpleTranslate(createAppTranslationKey('messages', 'OK'))}
                </Button>
            </div>
        </div>
    );
};

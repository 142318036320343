import { messageService } from 'api';
import { ListItemType, ActiveMode } from 'components';
import Entity from 'components/Entity/Entity';
import { useEffect, useState } from 'react';
import { showToast } from 'utils/toast';
import { MessageDTO } from 'shared';
import { setDisplayMode } from 'store';
import {
    getMessageFields,
    getMessageFieldsValues,
    MessageFormValues
} from './messages.config';

const Messages = () => {
    const [messages, setMessages] = useState<MessageDTO[]>([]);
    const [selectedMessage, setSelectedMessage] = useState<MessageDTO | null>(null);
    const [messageFieldValues, setMessageFieldsValues] = useState<MessageFormValues>(
        getMessageFieldsValues()
    );
    const [messageFilter, setMessageFilter] = useState<string>('');

    const fetchAllData = () => {
        messageService
            .getEntities()
            .then(data => {
                setMessages(data);
                setSelectedMessage(data[0]);
            })
            .catch(err => {
                // alert('The messages fetch failed with err: ' + err);
                showToast('The messages fetch failed with err: ' + err, 'error');
                console.log('The messages fetch failed with err: ' + err);
            });
        setDisplayMode('list');
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setMessageFieldsValues(getMessageFieldsValues(selectedMessage));
    }, [selectedMessage]);

    const updateSelectedEntity = (message?: MessageDTO | null) => {
        setSelectedMessage(message);
    };

    const onSubmit = (message?: MessageDTO) => {
        console.log(message);

        if (selectedMessage?.id) {
            console.log('Message editing');
            messageService
                .update({ ...selectedMessage, ...message })
                .then(resData => {
                    // alert('The message update was successful!');
                    showToast('The message update was successful!', 'success');
                    console.log('The message update was successful ' + resData);
                    fetchAllData();
                })
                .catch(err => {
                    // alert('The message update failed with err: ' + err);
                    showToast('The message update failed with err: ' + err, 'error');
                    console.log('The message update failed with err: ' + err);
                });
        } else {
            console.log('Message creation');
            messageService
                .create(message)
                .then(data => {
                    // alert('The message create was successful!');
                    showToast('The message create was successful!', 'success');
                    console.log('The message create was successful ' + data);
                    fetchAllData();
                })
                .catch(err => {
                    // alert('The message create failed with err: ' + err);
                    showToast('The message create failed with err: ' + err, 'error');
                    console.log('The message create failed with err: ' + err);
                });
        }
    };

    const onDeleteHandler = () => {
        console.log('delete');
        messageService
            .remove(selectedMessage)
            .then(resData => {
                console.log(resData);
                fetchAllData();
            })
            .catch(err => console.log(err));
    };

    const channelListItems: ListItemType[] = messages.map(message => ({
        id: message.id,
        title: message.description,
        subTitle: message.userFullName,
        avatarImg: message.userImg || message.id.toString(),
        component: <ActiveMode isActive={message.isActive} />,
        onClick: () => {
            updateSelectedEntity(message);
            setDisplayMode('form');
        }
    }));

    const filteredChannelListItems = channelListItems.filter(
        channel =>
            channel.title.toLowerCase()?.includes(messageFilter.toLowerCase()) ||
            channel.subTitle.toLowerCase().includes(messageFilter.toLowerCase())
    );

    const entityFields = getMessageFields();
    return (
        <Entity
            type="form"
            singularEntityName="הודעה"
            pluralEntityName="הודעות"
            entityFields={entityFields}
            entityFieldValues={messageFieldValues}
            selectedEntity={selectedMessage}
            onSubmit={onSubmit}
            onDelete={onDeleteHandler}
            updateSelectedEntity={updateSelectedEntity}
            filteredEntityListItems={filteredChannelListItems}
            entityFilter={messageFilter}
            setEntityFilter={setMessageFilter}
        />
    );
};

export default Messages;

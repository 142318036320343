import { Stack } from '@mui/material';
import classNames from 'classnames';
import { SwitchField } from 'components/Form/formTypes';
import CustomSwitch from 'components/Switch/Switch';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './switch.module.scss';

type SwitchProps = UseControllerProps & {
    dataObj: SwitchField;
    className?: string;
};

const Switch = ({ dataObj, name, control, className }: SwitchProps) => {
    const { field } = useController({
        name,
        control
    });

    const subDescription =
        typeof dataObj.subDescription === 'function'
            ? dataObj.subDescription(field.value)
            : dataObj.subDescription;

    return (
        <Stack
            className={classNames(className, styles.switchContainner)}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <div className={styles.titleContainner}>
                <span className={styles.title}>{dataObj.description}</span>
                <span className={styles.subTitle}>{subDescription}</span>
            </div>
            {field.value !== undefined && (
                <CustomSwitch
                    inputRef={field.ref}
                    onChange={field.onChange}
                    checked={field.value}
                />
            )}
        </Stack>
    );
};

export default Switch;

import { appConfig } from 'config';
import { mockUserData } from 'mockData';
import {
    QuickApiEntityService,
    QuickConsoleLogger,
    sleepAsync
} from 'quickode.infrastructure.js';
import { DeviceFetchDTO, User, UserDTO, UserDetailsDTO, Channel } from 'shared';
import { appApiProvider } from './axiosApiProvider';
import axios from 'axios';

const { useMockData } = appConfig;

class UserService extends QuickApiEntityService<UserDTO> {
    // implements QuickDataTableFetcher<UserTableDto>
    constructor() {
        super(new QuickConsoleLogger('UserService'), appApiProvider, 'user', 'User');
    }

    public async getUsersDetails(): Promise<UserDetailsDTO[]> {
        return this.getOrThrow<UserDetailsDTO[]>({ url: '/getUsersDetails' });
    }

    public async getUserDevices(): Promise<DeviceFetchDTO[]> {
        return this.getOrThrow<DeviceFetchDTO[]>({ url: '/devices' });
    }

    public async updateAuthLevelId(
        userDeviceId: number,
        deviceId: number,
        authLevelId: number
    ): Promise<UserDetailsDTO[]> {
        return this.putOrThrow<UserDetailsDTO[]>(
            { userDeviceId, deviceId, authLevelId },
            { url: '/devices/updateAuthLevelId' }
        );
    }

    // public async getFavorites(): Promise<Channel[]> {
    //     return this.getOrThrow<Channel[]>({url:'getFavorites'});
    // }

    public async addFavorite(channelId: number): Promise<Channel> {
        return this.postOrThrow({ channelId }, { url: 'addChannelFavorites' });
    }

    public async removeFavorite(channelId: number): Promise<Channel> {
        return this.postOrThrow({ channelId }, { url: 'removeChannelFavorites' });
    }

    public async getCurrentUserProfile(): Promise<User> {
        if (useMockData) {
            await sleepAsync(1000);

            return mockUserData;
        }
        return this.getOrThrow<User>({ url: 'get-current-user' });
    }

    public async uploadImage(formData: FormData): Promise<any> {
        // const response = await axios.post('/api/uploadImage', formData, {
        //     headers: { 'Content-Type': 'multipart/form-data' }
        // });
        // return response.status;
        return this.postOrThrow(formData, { url: 'uploadBackImage' });
    }

    // public async resetPassword(userId: number): Promise<void> {
    //     if (useMockData) {
    //         return sleepAsync(1000);
    //     }
    //     return this.getOrThrow({ url: `reset-password/${userId}` });
    // }

    // public async getUserIdByEmail(email: string): Promise<{ userId: number }> {
    //     if (useMockData) {
    //         await sleepAsync(1000);
    //         return {
    //             userId: mockUserData.id
    //         };
    //     }
    //     return this.postOrThrow({ email }, { url: 'get-id-by-email' });
    // }

    // public async fetchData(
    //     fetchInfo: QuickDataTableFetchInfo<UserTableDto>
    // ): Promise<QuickTableFetchedData<UserTableDto>> {
    //     if (useMockData) {
    //         await sleepAsync(1000);
    //         return mockUsersTableData;
    //     }
    //     const res = await this.post<QuickTableFetchedData<UserTableDto>>(fetchInfo, {
    //         url: 'users-table'
    //     });

    //     if (res.httpStatusCode !== QuickHttpStatusCode.OK) {
    //         console.error(`Cannot fetch user's table`, res);

    //         throw new Error('Cannot fetch data table');
    //     }

    //     return res.data;
    // }

    // public exportData(
    //     exportInfo: QuickDataTableExportInfo<UserTableDto>
    // ): Promise<UserTableDto[]> {
    //     throw new Error('Method not implemented.');
    // }
}

export const userService = new UserService();

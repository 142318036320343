import { Checkbox as MuiCheckbox } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { ChangeEvent } from 'react';

interface CheckboxProps {
    checked: boolean;
    handleChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const Checkbox = ({ checked, handleChange }: CheckboxProps) => {
    return (
        <MuiCheckbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            icon={<CircleUnchecked htmlColor="#6BB8F3" />}
            checkedIcon={<CheckCircle htmlColor="#6BB8F3" />}
        />
    );
};

export default Checkbox;

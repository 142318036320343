import {
    Chip,
    FormControl,
    FormHelperText,
    OutlinedInputProps,
    TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { compact } from 'lodash';
import { useState } from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';
import { useFormStyles } from '../quick-form-styles.hook';
import { QFFieldText } from '../quick-form.model';

export interface QuickMultiTextProps extends Omit<QFFieldText, 'type'> {
    name: string;
    label: string;
    style?: React.CSSProperties;
    control: Control<FieldValues, any>;
}

const useStyles = makeStyles({
    inputBase: {
        flexWrap: 'wrap',
        gap: '5px',
        padding: '8px'
    }
});

export const QuickMultiText = ({
    name,
    label,
    placeholder,
    style,
    readOnly,
    control,
    required,
    maxLength,
    rows
}: QuickMultiTextProps) => {
    const { sectionStyle } = useFormStyles(name, style);
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles();

    return (
        <section key={`section-${name}`} style={sectionStyle}>
            <Controller
                control={control}
                name={name}
                render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth>
                        <TextField
                            {...field}
                            onChange={event => setInputValue(event.target.value)}
                            onKeyDown={(event: any) => {
                                const value = event.target.value;
                                if (event.key === 'Enter' && value.trim() !== '') {
                                    event.preventDefault();
                                    field.onChange([
                                        ...(field.value || []),
                                        event.target.value
                                    ]);
                                    setInputValue('');
                                }
                            }}
                            multiline={Boolean(rows)}
                            rows={rows}
                            value={inputValue}
                            label={label}
                            placeholder={placeholder}
                            variant="outlined"
                            type="text"
                            InputProps={
                                {
                                    readOnly: Boolean(readOnly),
                                    disabled: Boolean(readOnly),
                                    autoComplete: name,
                                    classes: { root: classes.inputBase },
                                    startAdornment: (field.value || []).map(
                                        (chip: string, index: number) => (
                                            <Chip
                                                key={index}
                                                label={chip}
                                                onDelete={() => {
                                                    const chips = [...field.value];
                                                    chips.splice(index, 1);
                                                    field.onChange(chips);
                                                }}
                                            />
                                        )
                                    )
                                } as Partial<OutlinedInputProps>
                            }
                            InputLabelProps={{
                                shrink: true,
                                required: required,
                                disabled: Boolean(readOnly)
                            }}
                            inputProps={{
                                maxLength: maxLength,
                                style: {
                                    flex: 1,
                                    padding: 0,
                                    minWidth: '120px'
                                }
                            }}
                        />
                        {compact(error as any as FieldError[])?.length ? (
                            <FormHelperText error>
                                {compact(error as any as FieldError[])[0].message}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                )}
            />
        </section>
    );
};

import {
    createObjectState,
    QuickReduxDispatch
} from 'quickode.infrastructure.react/state';
import { messageBoxActions, setMessageBoxActions } from './quick-message-box.hook';
import { QuickMessageBoxItem } from './quick-message-box.model';

export const appendQuickMessageBoxState = (dispatch: QuickReduxDispatch) => {
    if (messageBoxActions) {
        throw new Error('Cannot initialize QuickMessageBoxItem more than once');
    }

    const quickMessageBox = createObjectState<QuickMessageBoxItem>(
        'quickMessageBox',
        dispatch,
        { initialValue: null }
    );
    setMessageBoxActions(quickMessageBox.actions);

    return {
        quickMessageBox
    };
};

import { DisplayModeObjType } from 'hooks/useDisplayMode';
import { appendDialogState } from 'quickode.infrastructure.react';
import { appendSpinnerState } from 'quickode.infrastructure.react/global-spinner';
import { appendQuickMessageBoxState } from 'quickode.infrastructure.react/quick-message-box/quick-message-box.store';
import {
    createArrayState,
    createObjectState,
    createValueState,
    QuickReduxDispatch
} from 'quickode.infrastructure.react/state';
import { appendTranslation } from 'quickode.infrastructure.react/translation';
import {
    TvModelDTO,
    Category,
    Channel,
    DeviceFetchDTO,
    User,
    AuthorityDTO,
    GroupFetchDTO
} from 'shared';
import { translations } from 'translation/he';

/**
 * We can use following types of states:
 * createArrayState
 * createObjectState
 * createValueState
 *
 * @param dispatch
 * @returns state
 */
export const createStates = (dispatch: QuickReduxDispatch) => {
    return {
        ...appendDialogState(dispatch),
        ...appendSpinnerState(dispatch),
        ...appendQuickMessageBoxState(dispatch),
        ...appendTranslation<typeof translations>(dispatch, {
            isRtl: true,
            translations
        }),
        user: createObjectState<User>('user', dispatch, {
            persist: true
        }),
        displayModeObj: createObjectState<DisplayModeObjType>(
            'displayModeObj',
            dispatch,
            {
                initialValue: {
                    isDesktop: true
                }
            }
        ),
        isBackMode: createValueState<boolean>('isBackMode', dispatch, {
            initialValue: false
        }),
        authorities: createArrayState<AuthorityDTO>('authorities', dispatch, {
            initialValue: null
        }),
        groups: createArrayState<GroupFetchDTO>('groups', dispatch, {
            initialValue: null
        }),
        categories: createArrayState<Category>('categories', dispatch, {
            initialValue: null
        }),
        tvModels: createArrayState<TvModelDTO>('tvModels', dispatch, {
            initialValue: null
        }),
        userDevices: createArrayState<DeviceFetchDTO>('devices', dispatch, {
            initialValue: null
        }),
        channels: createArrayState<Channel>('channels', dispatch, {
            initialValue: null
        }),
        userSelections: createObjectState<{
            device: DeviceFetchDTO | null;
            channel: Channel | null;
            group: GroupFetchDTO | null;
            openProfile: boolean | null;
        }>('devices', dispatch, {
            initialValue: {
                device: null,
                channel: null,
                group: null,
                openProfile: null
            }
        }),
        accessToken: createValueState<string>('accessToken', dispatch, {
            persist: false
        })
    };
};

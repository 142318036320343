import { FieldType, MappingFieldsType } from 'components/Form/formTypes';
import { MessageDTO } from 'shared';

export type ChannelFields = keyof MessageDTO;

export interface MessageFormValues {
    description: string;
    isActive: boolean;
}

export const getMessageFields = (): MappingFieldsType<ChannelFields> => ({
    description: {
        type: FieldType.INPUT,
        description: 'תוכן הודעה',
        options: {
            required: true,
            maxLength: 50
        }
    },
    isActive: {
        type: FieldType.SWITCH,
        description: 'סטטוס הודעה',
        subDescription: isActive => (isActive ? 'פעיל' : 'לא פעיל')
    }
});

export const getMessageFieldsValues = (message?: MessageDTO): MessageFormValues => ({
    description: message?.description || '',
    isActive: message?.isActive || false
});

import { ChannelFormValues } from 'pages/Channels/channel.config';
import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { Channel } from 'shared';
import { b64toFile } from 'utils/file';
import { appApiProvider } from './axiosApiProvider';

export class ChannelService extends QuickApiEntityService<Channel> {
    constructor() {
        super(
            new QuickConsoleLogger('ChannelService'),
            appApiProvider,
            'channels',
            'Channel'
        );
    }

    public async create(entity: ChannelFormValues): Promise<Channel> {
        const formData = new FormData();

        if (typeof entity.channelLogo === 'string') {
            entity.channelLogo = b64toFile(entity.channelLogo);
        }
        if (typeof entity.channelLogoMobile === 'string') {
            entity.channelLogoMobile = b64toFile(entity.channelLogoMobile);
        }

        Object.entries(entity).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return this.postOrThrow(formData);
    }

    public async update(entity: Channel): Promise<Channel> {
        const formData = new FormData();

        if (typeof entity.channelLogo === 'string') delete entity.channelLogo;
        if (typeof entity.channelLogoMobile === 'string') delete entity.channelLogoMobile;

        Object.entries(entity).forEach(([key, value]) => {
            formData.append(key, value);
        });
        return this.putOrThrow(formData);
    }

    public async changeOrdinalNum(channels: Channel[]): Promise<Channel[]> {
        return this.postOrThrow({ channels }, { url: 'changeOrdinalNum' });
    }
}

export const channelService = new ChannelService();

import { createSelector } from 'reselect';
import { AppState } from 'store/store';

export const getUserSelections = (state: AppState) => state.userSelections;

export const getSelectedUserDevice = createSelector(
    getUserSelections,
    userSelections => userSelections.device
);

export const getSelectedUserChannel = createSelector(
    getUserSelections,
    userSelections => userSelections.channel
);

export const getSelectedUserGroup = createSelector(
    getUserSelections,
    userSelections => userSelections.group
);
export const openProfile = createSelector(
    getUserSelections,
    userSelections => userSelections.openProfile
);

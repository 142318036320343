import { FormControl, Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, SetStateAction } from 'react';
import { SxConfig } from '@mui/system';
import styles from './inputFilter.module.scss';

interface InputFilterProps {
    placeholder: string;
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
    sx?: SxConfig;
}

export const InputFilter = ({ filter, setFilter, placeholder, sx }: InputFilterProps) => {
    return (
        <FormControl sx={sx} variant="standard" classes={{ root: styles.inputFilter }}>
            <Input
                value={filter}
                onChange={e => setFilter(e.target.value)}
                classes={{ input: styles.input }}
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon htmlColor="#8A8A8A" />
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

import { useEffect, useState } from 'react';
import { Device } from 'shared';
import { useSelector } from 'react-redux';
import { ButtonGroup, IconButton, Button as MUIButton, ButtonBase } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Kan11Logo from 'assets/channels/kan11.png';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as UnLikeIcon } from 'assets/icons/unlike.svg';
// import { ReactComponent as PowerIcon } from 'assets/icons/power.svg';
import PowerOnIcon from '@mui/icons-material/PowerSettingsNew';

import MuteIcon from '@mui/icons-material/VolumeOff';
import UnmuteIcon from '@mui/icons-material/VolumeUp';
import { ReactComponent as HDMILogo } from 'assets/icons/hdmi.svg';
import { useBreakpointsMediaQuery } from 'hooks';
import { deviceService, userService } from 'api';
import {
    actions,
    getSelectedUserChannel,
    getSelectedUserDevice,
    getSelectedUserGroup,
    store
} from 'store';
import classNames from 'classnames';
import { useDialog } from 'quickode.infrastructure.react';
import { showToast } from 'utils/toast';
import Hdmi from './components/Hdmi/Hdmi';
import styles from './footer.module.scss';
import UserDevicesDialog from './components/UserDevicesDialog/UserDevicesDialog';
import { DialogRes } from './components/UserDevicesDialog/userDevicesDialog.config';
import UserAdminDialog from './components/UserAdminDialog/UserAdminDialog';

export const Footer = () => {
    const [isHDMIOpen, setIsHDMIOpen] = useState<boolean>(false);
    const [selectedHDMI, setSelectedHDMI] = useState<number>(1);
    const [offStatus, setOffStatus] = useState<boolean>(false);

    const isDesktop = useBreakpointsMediaQuery('breackpointDesktop');

    const selectedDevice = useSelector(getSelectedUserDevice);
    const selectedGroup = useSelector(getSelectedUserGroup);
    const selectedChannel = useSelector(getSelectedUserChannel);
    const [mute, setMute] = useState(false);

    const { openDialog } = useDialog();

    useEffect(() => {
        if (selectedDevice) {
            setMute(selectedDevice.isMute);
        }
    }, [selectedDevice]);

    const clickMuteHandler = () => {
        let devicesIds = [];
        if (selectedGroup) {
            devicesIds = [...selectedGroup.devices.map(group => group.id)];
        } else {
            devicesIds = [selectedDevice.id];
        }

        deviceService
            .mute(devicesIds, mute, selectedGroup?.id)
            .then(devices => {
                if (selectedDevice) {
                    setMute(devices[0].isMute);
                    // alert(devices[0].isMute ? 'mute succeed!' : 'unmute succeed!');
                    showToast(
                        devices[0].isMute ? 'mute succeed!' : 'unmute succeed!',
                        'success'
                    );
                    actions.userSelections.partialUpdate({ device: devices[0] });
                    actions.userDevices.partialUpdateWhere(
                        devices[0],
                        item => item.id === devices[0].id
                    );
                } else {
                    // alert(
                    //     selectedGroup && selectedGroup.devices.length > 0
                    //         ? 'mute succeed!'
                    //         : 'unmute succeed!'
                    // );
                    showToast(
                        selectedGroup && selectedGroup.devices.length > 0
                            ? 'mute succeed!'
                            : 'unmute succeed!',
                        'success'
                    );
                    setMute(devices[0].isMute);
                    actions.userSelections.partialUpdate({
                        group: { ...selectedGroup, devices }
                    });
                }
            })
            .catch(err => {
                // alert('mute failed with err:' + err);
                showToast('mute failed with err:' + err, 'error');
            });
    };

    const clickVolumeHandler = (volumeToAdd: number) => {
        let devicesIds = [];
        if (selectedGroup) {
            devicesIds = [...selectedGroup.devices.map(group => group.id)];
        } else {
            devicesIds = [selectedDevice.id];
        }
        deviceService
            .volume(devicesIds, volumeToAdd, selectedGroup?.id)
            .then(devices => {
                if (selectedDevice) {
                    const device = devices[0];
                    // alert(`A value of ${device.volume} is summed for volume`);
                    showToast(
                        `A value of ${volumeToAdd} is summed for volume`,
                        'success'
                    );

                    actions.userSelections.partialUpdate({ device });
                    actions.userDevices.partialUpdateWhere(
                        device,
                        (item: Device) => item.id === device.id
                    );
                } else {
                    // alert(`A value of ${devices[0].volume} is summed for volume`);
                    showToast(
                        `A value of ${volumeToAdd} is summed for volume`,
                        'success'
                    );
                    actions.userSelections.partialUpdate({
                        group: { ...selectedGroup, devices }
                    });
                }
            })
            .catch(err => {
                showToast('volume failed with err:' + err, 'error');

                // alert('volume failed with err:' + err);
            });
    };

    const changeFavorites = () => {
        if (selectedChannel.inFavorites) {
            userService.removeFavorite(selectedChannel.id).then(channel => {
                // alert(`Removed channel ${selectedChannel.channelNameHE} from favorites`);
                showToast(
                    `Removed channel ${selectedChannel.channelNameHE} from favorites`,
                    'success'
                );
                actions.userSelections.partialUpdate({ channel });

                actions.channels.partialUpdateWhere(
                    channel,
                    item => item.id === channel.id
                );
            });
        } else {
            userService.addFavorite(selectedChannel.id).then(channel => {
                // alert(`Add channel ${selectedChannel.channelNameHE} to favorites`);
                showToast(
                    `Add channel ${selectedChannel.channelNameHE} to favorites`,
                    'success'
                );
                actions.userSelections.partialUpdate({ channel });

                actions.channels.partialUpdateWhere(
                    channel,
                    item => item.id === channel.id
                );
            });
        }
    };

    const changeHDMI = (hdmiInput: any) => {
        let devicesIds: number[] = [];
        if (selectedGroup) {
            devicesIds = [...selectedGroup.devices.map(group => group.id)];
        } else {
            devicesIds = [selectedDevice.id];
        }
        deviceService
            .changeHDMI(devicesIds, hdmiInput, selectedGroup?.id)
            .then(data => {
                // alert(`An HDMI input change to ${hdmiInput} `);
                showToast(`An HDMI input change to ${hdmiInput} `, 'success');
                setSelectedHDMI(hdmiInput);
            })
            .catch(err => {
                showToast(`Change HDMI is failed with err: ` + err, 'error');
            });
    };

    const openUserDevicesDialog = async () => {
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return <UserDevicesDialog handleClose={handleClose} />;
            }
        });

        if (res.actionCode === 'Save') {
            actions.userSelections.partialUpdate({ device: res.userDevice, group: null });
        }
    };

    const openAdminDialog = async () => {
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return <UserAdminDialog handleClose={handleClose} />;
            }
        });

        if (res.actionCode === 'Save') {
            if (res.group) {
                actions.userSelections.partialUpdate({ group: res.group, device: null });
            } else {
                actions.userSelections.partialUpdate({
                    group: null,
                    device: res.userDevice
                });
            }
        }
    };

    const clickPowerHandler = async () => {
        let devicesIds = [];
        if (selectedGroup) {
            devicesIds = [...selectedGroup.devices.map(group => group.id)];
        } else {
            devicesIds = [selectedDevice.id];
        }

        deviceService
            .power(devicesIds, offStatus ? 1 : 0, selectedGroup?.id)
            .then(() => {
                // alert(`An HDMI input change to ${hdmiInput} `);
                setOffStatus(!offStatus);
                showToast(`Click on Power button `, 'success');
            })
            .catch(error => {
                showToast(`Click on Power button failed with err: ` + error, 'error');
            });
    };

    const checkDialog = async () => {
        if (store.getState().user.role === 'Admin') {
            return openAdminDialog();
        }

        return openUserDevicesDialog();
    };

    return (
        <div className={styles.footer}>
            <div className={styles.info}>
                {isDesktop && <img alt="logo" src={Kan11Logo} />}
                <div className={styles.details}>
                    <span className={styles.owner} onClick={checkDialog}>
                        {selectedDevice ? selectedDevice.neme : selectedGroup?.group_name}
                    </span>
                    <div className={styles.channel}>
                        <span className={styles.channelName}>
                            {selectedChannel?.channelNameHE}
                        </span>
                        <span className={styles.indicator}>עכשיו</span>
                    </div>
                </div>
                {!isDesktop && false && (
                    <ButtonBase
                        id="hdmiMenuButton"
                        className={classNames({
                            [styles.hdmiBtn]: true,
                            [styles.active]: isHDMIOpen
                        })}
                        onClick={() => setIsHDMIOpen(prev => !prev)}>
                        <div>
                            <HDMILogo />
                        </div>
                        {selectedHDMI && <span>HDMI{selectedHDMI}</span>}
                    </ButtonBase>
                )}
            </div>
            {!isHDMIOpen && (
                <div className={styles.actions}>
                    {!isDesktop && <img alt="logo" src={Kan11Logo} />}
                    <MUIButton
                        onClick={() => clickPowerHandler()}
                        style={{ minWidth: 'min-content' }}>
                        <PowerOnIcon
                            htmlColor="white"
                            sx={{ width: '30px', height: '30px' }}
                        />
                    </MUIButton>
                    <IconButton
                        color="primary"
                        component="label"
                        onClick={() => changeFavorites()}>
                        {selectedChannel?.inFavorites ? <LikeIcon /> : <UnLikeIcon />}
                    </IconButton>
                    <ButtonGroup variant="contained">
                        <MUIButton onClick={() => clickVolumeHandler(1)}>
                            <PlusIcon />
                        </MUIButton>
                        <MUIButton onClick={() => clickVolumeHandler(-1)}>
                            <MinusIcon />
                        </MUIButton>
                    </ButtonGroup>
                    <IconButton
                        color="primary"
                        component="label"
                        onClick={() => clickMuteHandler()}>
                        {mute ? (
                            <MuteIcon
                                htmlColor="white"
                                sx={{ width: '30px', height: '30px' }}
                            />
                        ) : (
                            <UnmuteIcon
                                htmlColor="white"
                                sx={{ width: '30px', height: '30px' }}
                            />
                        )}
                    </IconButton>
                </div>
            )}
            {(isDesktop || isHDMIOpen) && false && (
                <Hdmi selectedHDMI={selectedHDMI} changeHDMI={changeHDMI} />
            )}
        </div>
    );
};

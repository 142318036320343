import { createTheme, ThemeProvider } from '@mui/material';
import { theme } from 'layout/theme/theme';
import { DialogsContainer, GlobalSpinner } from 'quickode.infrastructure.react';
import { QuickMessageBox } from 'quickode.infrastructure.react/quick-message-box/quick-message-box';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from 'router';
import { loginRequest, msalConfig } from 'auth/authConfig';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { appApiProvider } from 'api/axiosApiProvider';
import { ToastContainer } from 'react-toastify';
import styles from './index.module.scss';
import './assets/fonts/SimplerPro_V3-Regular.otf';
import { actions, persistor, store } from './store';

export const msalInstance = new PublicClientApplication(msalConfig);

if (msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = { ...payload.account };
        msalInstance.setActiveAccount(account);
        msalInstance.acquireTokenSilent({ ...loginRequest });
    }
});

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const token = payload.accessToken;

        actions.accessToken.set(token);
        appApiProvider.updateJwtToken(token);
    }
});

const AppWithCallbackAfterRender = () => {
    return (
        <ThemeProvider theme={createTheme(theme)}>
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AppRouter />
                        <ToastContainer
                            bodyClassName={styles.bodyToast}
                            toastClassName={styles.toastMain}
                            limit={1}
                        />
                    </PersistGate>
                    <DialogsContainer />
                    <GlobalSpinner />
                    <QuickMessageBox />
                </Provider>
            </MsalProvider>
        </ThemeProvider>
    );
};

const container = document.getElementById('root');
const root = (ReactDOM as any).createRoot(container);
root.render(<AppWithCallbackAfterRender />);

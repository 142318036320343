import { IconButton, MenuItem, Select } from '@mui/material';
import { deviceService } from 'api';
import {
    ActionType,
    Section,
    FieldType,
    MappingFieldsType
} from 'components/Form/formTypes';
import { AuthorityDTO, DeviceFetchDTO, TvModelDTO } from 'shared';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { showToast } from 'utils/toast';
import UserSectionActions from './components/UserSection/UserSectionActions';

export type DeviceFields = keyof DeviceFetchDTO | 'ressetBtn';

const resetDevicePi = async (deviceId: number) => {
    deviceService
        .resetPi(deviceId)
        .then(() => {
            // alert(`Reset device #${deviceId} succeeded!`);
            showToast(`Reset device #${deviceId} succeeded!`, 'success');
            console.log(`Reset device #${deviceId} succeeded!`);
        })
        .catch(err => {
            // alert(`Reset device #${deviceId} failed!`);
            showToast(`Reset device #${deviceId} failed!`, 'error');
            console.log(`Reset device #${deviceId} failed! `, err);
        });
};

export const getDeviceFields = (
    selectedDevice?: DeviceFetchDTO,
    tvModels?: TvModelDTO[]
): MappingFieldsType<DeviceFields> => ({
    id: {
        type: FieldType.String,
        description: 'ID מכשיר',
        value: selectedDevice?.id.toString()
    },
    ip: {
        type: FieldType.String,
        description: 'IP מכשיר',
        value: selectedDevice?.ip.toString()
    },
    neme: {
        type: FieldType.INPUT,
        description: 'שם מכשיר',
        options: {
            required: true,
            maxLength: 50
        }
    },
    tvModelId: {
        type: FieldType.SELECT,
        description: 'סוג הטלוויזיה',
        optionItems: tvModels
            ? tvModels
                  .map(tvModel => ({
                      value: tvModel.id,
                      desctiption: tvModel.tvBrand
                  }))
                  .concat([{ value: null, desctiption: 'default' }])
            : [],
        options: {
            required: false
        }
    },
    ressetBtn: {
        type: ActionType.BUTTOM,
        text: 'Restart Pi',
        variant: 'outlined',
        onClick: () => resetDevicePi(selectedDevice.id)
    },
    status: {
        type: FieldType.SWITCH,
        description: 'סטטוס מכשיר',
        subDescription: isActive => (isActive ? 'מכשיר פעיל' : 'מכשיר לא פעיל')
    }
});

export const getDeviceFieldsValues = (
    device?: DeviceFetchDTO
): Partial<DeviceFetchDTO> => ({
    id: device?.id,
    ip: device?.ip || '',
    neme: device?.neme || '',
    tvModelId: device?.tvModelId,
    status: device?.status || false,
    relatedUsers: device?.relatedUsers
});

export const getDeviceAdditionalSections = (
    authorities: AuthorityDTO[]
): Section<keyof DeviceFetchDTO>[] => [
    {
        title: 'משתמשים בעלי הרשאה',
        subTitle: watch => {
            const { relatedUsers } = watch();
            return relatedUsers ? `${relatedUsers.length} משתמשים` : undefined;
        },
        ActionsComponent: ({ setValue }) => (
            <UserSectionActions keyForm="relatedUsers" setValue={setValue} />
        ),
        fields: {
            relatedUsers: {
                type: FieldType.GENERIC_LIST,
                description: '',
                listItem: (userDevice, setFormValue, userDevicesForm) => [
                    [
                        {
                            type: 'avatarImg',
                            avatarImg: userDevice.img || ''
                        },
                        {
                            type: 'title',
                            title: userDevice.email,
                            subTitle: userDevice.roleName
                        }
                    ],
                    [
                        {
                            type: 'component',
                            style: { marginLeft: '4px' },
                            component: (
                                <Select
                                    classes={{}}
                                    sx={{
                                        width: '150px!important',
                                        padding: '0 !important'
                                    }}
                                    value={userDevice.authLevelId || authorities[0]?.id}
                                    onChange={({ target }) => {
                                        const userDevicesFormToEdit = userDevicesForm.map(
                                            user => ({
                                                ...user,
                                                authLevelId:
                                                    user.id === userDevice.id
                                                        ? target.value
                                                        : user.authLevelId
                                            })
                                        );

                                        setFormValue(userDevicesFormToEdit);
                                    }}>
                                    {authorities?.map(authority => (
                                        <MenuItem key={authority.id} value={authority.id}>
                                            {authority.authDescription}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )
                        },
                        {
                            type: 'component',
                            component: (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        if (window.confirm('האם למחוק משתמש זה?')) {
                                            setFormValue(
                                                userDevicesForm.filter(
                                                    userDeviceForm =>
                                                        userDeviceForm.id !==
                                                        userDevice.id
                                                )
                                            );
                                        }
                                    }}>
                                    <TrashIcon />
                                </IconButton>
                            )
                        }
                    ]
                ]
            }
        }
    }
];

import { FC } from 'react';
import { styled, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useBreakpointsMediaQuery } from 'hooks';
import { ComponentGuard } from 'auth/ComponentGuard';
import { groups } from 'auth/authConfig';
import styles from './header.module.scss';
import SideMenuControllers from '../SideMenuControllers/SideMenuControllers';
import InfoSection from '../InfoSection/InfoSection';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(
    MuiAppBar,
    {}
)<AppBarProps>(({ theme }) => ({
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
}));

interface HeaderProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

export const Header: FC<HeaderProps> = ({ open, handleDrawerToggle }) => {
    const isTablets = useBreakpointsMediaQuery('breackpointTablets');
    return (
        <AppBar className={styles.header} position="fixed" open={open}>
            <Toolbar classes={{ root: styles.toolbar }}>
                {isTablets ? (
                    <InfoSection open={open} handleDrawerToggle={handleDrawerToggle} />
                ) : (
                    <ComponentGuard requriedGroups={[groups.groupAdmin]}>
                        <SideMenuControllers
                            open={open}
                            handleDrawerToggle={handleDrawerToggle}
                        />
                    </ComponentGuard>
                )}
            </Toolbar>
        </AppBar>
    );
};

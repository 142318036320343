import MuiDrawer from '@mui/material/Drawer';
import { ReactComponent as Kan11Logo } from 'assets/mainLayout/header/Kan11Logo.svg';
import { FC } from 'react';
import classesnames from 'classnames';
import { SideMenu } from '..';
import InfoSection from '../InfoSection/InfoSection';
import styles from './menuDrawer.module.scss';

/* const createSX = (sxObjects: Record<string, SxProps<Theme>>) => sxObjects; */
interface MenuDrawerProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

export const MenuDrawer: FC<MenuDrawerProps> = ({ open, handleDrawerToggle }) => (
    <MuiDrawer
        className={classesnames({ [styles.menuDrawer]: true, [styles.open]: open })}
        classes={{
            paper: classesnames({
                [styles.paper]: true,
                [styles.open]: open
            })
        }}
        variant="permanent"
        open={open}>
        <div className={styles.innerPaperContainer}>
            <div className={styles.menuContentContainer}>
                <InfoSection open={open} handleDrawerToggle={handleDrawerToggle} />
                <Kan11Logo className={styles.kan11Logo} />
                <SideMenu handleDrawerToggle={handleDrawerToggle} />
                <div className={styles.versionDiv}>
                    Version: {process.env.REACT_APP_VERSION}
                </div>
            </div>
        </div>
    </MuiDrawer>
);

import { appApiProvider } from 'api/axiosApiProvider';
import { createQuickStore } from 'quickode.infrastructure.react/state/quick-store-factory';
import { historyInstance } from '../router/history';
import { createStates } from './state';

export const { actions, store, persistor } = createQuickStore(
    createStates,
    historyInstance
);

persistor.subscribe(() => {
    const { accessToken } = store.getState();

    appApiProvider.updateJwtToken(accessToken);
});

export type AppState = ReturnType<typeof store.getState>;

import { useSelector } from 'react-redux';
import { createObjectState, QuickReduxDispatch } from '../state';
import { QuickStateObjectActions } from '../state/state-object/state-object-actions';
import { QuickGlobalSpinner } from './global-spinner.model';
import { getSpinnerState } from './global-spinner.selectors';

let spinnerActions: QuickStateObjectActions<QuickGlobalSpinner>;

export const appendSpinnerState = (dispatch: QuickReduxDispatch) => {
    if (spinnerActions) {
        throw new Error('Cannot initialize QuickGlobalSpinner more than once');
    }

    const spinner = createObjectState<QuickGlobalSpinner>('spinner', dispatch, {
        initialValue: {
            isVisible: false,
            text: null
        }
    });

    spinnerActions = spinner.actions;

    return {
        spinner
    };
};

export const useSpinner = () => {
    const spinnerState = useSelector(getSpinnerState);

    const startSpinner = (loadingText?: string) => {
        spinnerActions.set({
            isVisible: true,
            text: loadingText
        });
    };

    const stopSpinner = () => {
        spinnerActions.clear();
    };

    return {
        startSpinner,
        stopSpinner,
        isVisible: spinnerState.isVisible
    };
};

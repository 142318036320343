import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { appNavigator } from 'router';
import { GeneralNavRoutes } from 'shared';
import { useAuth } from 'hooks';
import { useSelector } from 'react-redux';
import { actions, getCurrentUser, getIsUserAuthenticated } from 'store';
import { InteractionStatus } from '@azure/msal-browser';
import { authService } from 'api';
import { ComponentGuard } from 'auth/ComponentGuard';
import { groups } from 'auth/authConfig';
import styles from './mainLayout.module.scss';
import { Header, MenuDrawer } from './components';

export const MainLayout: FC = () => {
    const [isSideBarOpen, setSideBarOpen] = useState(false);
    const isAuthenticated = useSelector(getIsUserAuthenticated);
    const currentUser = useSelector(getCurrentUser);
    const { instance, inProgress } = useMsal();
    const { getToken } = useAuth();
    const handleDrawerToggle = () => {
        setSideBarOpen(isOpen => !isOpen);
    };

    // no active account in storage, go to sign-in page
    useEffect(() => {
        const onLoad = async () => {
            if (!instance.getActiveAccount() && inProgress === InteractionStatus.None) {
                appNavigator.navigate(GeneralNavRoutes.Login);
            }
        };
        onLoad();
    }, [instance, inProgress]);

    // no access token, get access token
    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                await getToken();
            }
        })();
    }, [isAuthenticated, getToken]);

    // have token but no User, sign in with BE
    useEffect(() => {
        (async () => {
            if (!currentUser && isAuthenticated) {
                const { user, errorTranslationKey } = await authService.login();
                console.log(user);

                if (errorTranslationKey) {
                    appNavigator.navigate(GeneralNavRoutes.Login);
                } else {
                    actions.user.set(user);
                }
            }
        })();
    }, [currentUser, isAuthenticated]);

    return (
        <div className={styles.mainLayout}>
            <Header open={isSideBarOpen} handleDrawerToggle={handleDrawerToggle} />
            <ComponentGuard requriedGroups={[groups.groupAdmin]}>
                <MenuDrawer
                    open={isSideBarOpen}
                    handleDrawerToggle={handleDrawerToggle}
                />
            </ComponentGuard>
            {isAuthenticated && (
                <Box component="main" className={styles.main}>
                    <Outlet />
                </Box>
            )}
        </div>
    );
};

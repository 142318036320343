import { deviceService, tvModelsService } from 'api';
import { ListItemType, ActiveMode } from 'components';
import Entity from 'components/Entity/Entity';
import { useEffect, useState } from 'react';
import { DeviceFetchDTO, TvModelDTO } from 'shared';
import { actions, AppState, getAuthorities, setDisplayMode } from 'store';
import { useSelector } from 'react-redux';
import { getIsTextFiltered } from 'utils/filter';
import { showToast } from 'utils/toast';
import {
    getDeviceFields,
    getDeviceFieldsValues,
    getDeviceAdditionalSections
} from './devices.config';

const Devices = () => {
    const [devices, setDevices] = useState<DeviceFetchDTO[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<DeviceFetchDTO | null>(null);
    const [deviceFieldValues, setDeviceFieldsValues] = useState<Partial<DeviceFetchDTO>>(
        getDeviceFieldsValues()
    );
    const [deviceFilter, setDeviceFilter] = useState<string>('');

    const tvModels = useSelector<AppState, TvModelDTO[]>(state => state.tvModels);
    const authorities = useSelector(getAuthorities);

    const fetchAllData = (options?: { noSetSelectedChannel: boolean }) => {
        tvModelsService
            .getEntities()
            .then(data => {
                actions.tvModels.set(data);
            })
            .catch(err => {
                console.log(err);
            });

        deviceService
            .getEntities()
            .then(data => {
                setDevices(data);
                if (!options?.noSetSelectedChannel) {
                    setSelectedDevice(data[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        setDeviceFieldsValues(getDeviceFieldsValues(selectedDevice));
    }, [selectedDevice]);

    const updateSelectedEntity = (device?: DeviceFetchDTO | null) => {
        setSelectedDevice(device);
    };

    const onSubmit = (device?: DeviceFetchDTO) => {
        if (selectedDevice?.id) {
            console.log('Device editing');
            deviceService
                .update(device)
                .then(resData => {
                    // alert('The device update was successful!');
                    showToast('The device update was successful!', 'success');

                    console.log('The device update was successful ' + resData);
                    fetchAllData({ noSetSelectedChannel: true });
                })
                .catch(err => {
                    // alert('The device update failed with err: ' + err);
                    showToast('The device update failed with err: ' + err, 'error');
                    console.log('The device update failed with err: ' + err);
                });
        } else {
            console.log('Device creation');
            deviceService
                .create(device)
                .then(data => {
                    // alert('The device create was successful!');
                    showToast('The device create was successful!', 'success');
                    console.log('The device create was successful ' + data);
                    fetchAllData();
                })
                .catch(err => {
                    // alert('The device create failed with err: ' + err);
                    showToast('The device create failed with err: ' + err, 'error');
                    console.log('The device create failed with err: ' + err);
                });
        }
    };

    const onDeleteDevice = () => {
        deviceService
            .remove(selectedDevice)
            .then(() => {
                console.log(
                    `The deletion of device ${selectedDevice.id} was successful!`
                );
                // alert(`The deletion of device ${selectedDevice.id} was successful!`);
                showToast(
                    `The deletion of device ${selectedDevice.id} was successful!`,
                    'success'
                );

                fetchAllData();
            })
            .catch(err => {
                console.log(`The deletion of device ${selectedDevice.id} failed!`);
                // alert(`The deletion of device ${selectedDevice.id} failed!`);
                showToast(`The deletion of device ${selectedDevice.id} failed!`, err);
            });
    };

    const deviceListItems: ListItemType[] = devices.map(device => ({
        id: device.id,
        title: device.neme || '',
        subTitle: `${device.id.toString()} :Device ID` || '',
        onClick: () => {
            updateSelectedEntity(device);
            setDisplayMode('form');
        },
        component: <ActiveMode isActive={device.status} />
    }));

    const filteredDeviceListItems = deviceListItems.filter(
        device =>
            getIsTextFiltered(device.title.toLowerCase(), deviceFilter.toLowerCase()) ||
            getIsTextFiltered(
                device.subTitle.toLowerCase(),
                deviceFilter.toLowerCase()
            ) ||
            getIsTextFiltered(device.id.toString(), deviceFilter.toLowerCase())
    );

    const deviceFields = getDeviceFields(selectedDevice, tvModels);
    const deviceAdditionalSections = getDeviceAdditionalSections(authorities);

    return (
        <Entity
            type="form"
            onDelete={onDeleteDevice}
            creatable={false}
            singularEntityName="מכשיר"
            pluralEntityName="מכשירים"
            entityFields={deviceFields}
            entityAdditionalSections={deviceAdditionalSections}
            entityFieldValues={deviceFieldValues}
            selectedEntity={selectedDevice}
            onSubmit={onSubmit}
            updateSelectedEntity={updateSelectedEntity}
            filteredEntityListItems={filteredDeviceListItems}
            entityFilter={deviceFilter}
            setEntityFilter={setDeviceFilter}
        />
    );
};

export default Devices;

import { PropsWithChildren } from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';
import classNames from 'classnames';
import styles from './button.module.scss';

export type ButtonProps = PropsWithChildren<
    {
        onClick?: () => void;
        isActive?: boolean;
        variant?: 'outlined' | 'contained' | 'text';
    } & MUIButtonProps
>;

export const Button = ({
    children,
    onClick,
    isActive = true,
    variant = 'contained',
    className,
    ...rest
}: ButtonProps) => (
    <MUIButton
        {...rest}
        onClick={onClick}
        className={classNames({ [styles.active]: !isActive }, styles[variant], className)}
        classes={{ root: styles.root }}>
        {children}
    </MUIButton>
);

/* 


export type ButtonProps = PropsWithChildren<
    {
        onClick?: () => void;
        isActive?: boolean;
        variant?: 'outlined' | 'contained' | 'text';
    } & ButtonBaseProps
>;

const Button = ({
    children,
    onClick,
    isActive = true,
    variant = 'contained',
    className,
    ...rest
}: ButtonProps) => (
    <ButtonBase
        {...rest}
        onClick={onClick}
        className={classNames({ [styles.active]: !isActive }, styles[variant], className)}
        classes={{ root: styles.root, disabled: styles.disabled }}>
        {children}
    </ButtonBase>
); */

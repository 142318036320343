import { camelCase } from 'lodash';

export const camelizeKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key])
            }),
            {}
        );
    }
    return obj;
};

export const getFilenameFromURL = (fileURL?: string) => {
    if (!fileURL) {
        return null;
    }
    const decodedURI = decodeURI(fileURL);
    const partsFromSplitURL = decodedURI.split('/');
    return partsFromSplitURL[partsFromSplitURL.length - 1];
};

import MuiDialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { getDialogs } from './dialog.selector';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    }
});

export const DialogsContainer = () => {
    const dialogs = useSelector(getDialogs);

    const classes = useStyles();

    return (
        <>
            {dialogs.map(({ render, handleClose, key }) => (
                <MuiDialog open aria-labelledby="customized-dialog-title" key={key}>
                    <div className={classes.container}>{render(handleClose)}</div>
                </MuiDialog>
            ))}
        </>
    );
};

import { useEffect, useState } from 'react';
import { showToast } from 'utils/toast';
import { GeneralNavRoutes } from 'shared';

import { Button } from 'components/Button';
import DialogBody from 'components/DialogBody/DialogBody';
import { Avatar, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/mainLayout/infoSection/Close.svg';
import { useSelector } from 'react-redux';
import { b64toFile, isImage } from 'utils/file';

import {
    getAuthorities,
    getCurrentUser,
    getCurrentUserInitials,
    getUserDevices
} from 'store';
import { ScrollbarContainner } from 'components';
import { appNavigator } from 'router';
import styles from './profileDialog.module.scss';
import { DialogRes } from './profileDialog.config';
import { userService } from 'api';
import { groups } from 'auth/authConfig';
import { ComponentGuard } from 'auth/ComponentGuard';

interface ProfileDialogProps {
    handleClose: (res: DialogRes) => void;
}

const ProfileDialog = ({ handleClose }: ProfileDialogProps) => {
    const currentUserInitials = useSelector(getCurrentUserInitials);

    const currentUser = useSelector(getCurrentUser);
    const userDevices = useSelector(getUserDevices);
    const authorities = useSelector(getAuthorities);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [previewImage, setPreviewImage] = useState(null);

    const logout = () => {
        appNavigator.navigate(GeneralNavRoutes.Logout);
    };

    const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files[0];
        if (isImage(fileUploaded.type)) {
            const reader = new FileReader();
            reader.onload = e => {
                setPreviewImage(reader.result);
                setSelectedImage(e.target.result as string);
            };
            reader.readAsDataURL(fileUploaded);
        } else {
            // alert('פורמט לא תקין! ניתן להעלות קבצים מסוג: jpg, jpeg, png');
            showToast('פורמט לא תקין! ניתן להעלות קבצים מסוג: jpg, jpeg, png', 'error');
        }
    };

    const save = () => {
        if (selectedImage) {
            const formData = new FormData();
            formData.append('image', selectedImage);
            userService.uploadImage(formData).then(response => {
                showToast(response, 'success');
                setSelectedImage(null);
                setPreviewImage(null);
                formData.delete('image');
                handleClose({ actionCode: 'Cancel' });
                window.location.reload();
            });
        } else {
            showToast('פורמט לא תקין! ניתן להעלות קבצים מסוג: jpg, jpeg, png', 'error');
        }
    };

    return (
        <DialogBody
            header={
                <IconButton style={{ position: 'absolute' }}>
                    <CloseIcon onClick={() => handleClose({ actionCode: 'Cancel' })} />
                </IconButton>
            }
            footer={
                <div className={styles.footer}>
                    <Button
                        style={{
                            flexGrow: 1,
                            margin: '1px',
                            flex: '1',
                            width: '100%'
                        }}
                        onClick={logout}>
                        התנתקות
                    </Button>
                </div>
            }>
            <div style={{ alignSelf: 'center' }}>
                <Avatar
                    sx={{
                        width: '120px',
                        height: '120px'
                    }}>
                    {currentUser?.img ? (
                        <img
                            src={currentUser.img}
                            alt="me"
                            style={{
                                width: '120px'
                            }}
                        />
                    ) : (
                        currentUserInitials
                    )}
                </Avatar>
            </div>

            <div
                style={{
                    display: 'flex',
                    flex: '1'
                }}>
                <div
                    style={{
                        flexGrow: 1,
                        border: '1px solid grey',
                        borderRadius: '15px',
                        padding: '5px',
                        margin: '5px 5px',
                        width: '50%'
                    }}>
                    <span className={styles.title}>כרטיס אישי</span>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'column'
                        }}>
                        <span style={{ margin: '5px', color: 'whitesmoke' }}>
                            שם משתמש: {currentUser.firstName} {currentUser.lastName}
                        </span>
                        <span style={{ margin: '5px', color: 'whitesmoke' }}>
                            מייל: {currentUser.email}{' '}
                        </span>
                        <span style={{ margin: '5px', color: 'whitesmoke' }}>
                            טלפון: {currentUser.phone}{' '}
                        </span>
                        <span style={{ margin: '5px', color: 'whitesmoke' }}>
                            תפקיד: {currentUser.title}{' '}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        border: '1px solid grey',
                        borderRadius: '15px',
                        padding: '5px',
                        margin: '5px 5px',
                        textAlign: 'center',
                        width: '50%'
                    }}>
                    <span className={styles.title}>מכשירים פעילים</span>
                    <div style={{ display: 'flex' }}>
                        <span id="deviceName" style={{ width: '50%' }}>
                            שם מכשיר
                        </span>
                        <span id="deviceAuth" style={{ width: '50%' }}>
                            רמת הרשאות
                        </span>
                    </div>
                    <ScrollbarContainner className={styles.genericListContainer}>
                        {userDevices
                            .filter(device => device.status)
                            .map(device => (
                                <div
                                    id={device.id.toString()}
                                    style={{
                                        display: 'flex',
                                        textAlign: 'right',
                                        padding: '1px',
                                        margin: '1px'
                                    }}
                                    className={
                                        device.status ? styles.active : styles.noActive
                                    }>
                                    <div style={{ width: '50%' }}>{device.neme}</div>
                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                        {
                                            authorities.filter(
                                                authority =>
                                                    authority.id ===
                                                    device.relatedUsers[0].authLevelId
                                            )[0]?.authDescription
                                        }
                                    </div>
                                </div>
                            ))}
                    </ScrollbarContainner>
                    {/* <div>
                        <span style={{ color: 'whitesmoke' }}>
                             סה&quot;כ מכשירים: {userDevices.length}}
                        </span>
                    </div> */}
                </div>
            </div>
            <ComponentGuard requriedGroups={[groups.groupAdmin]}>
                <div
                    style={{
                        display: 'flex',
                        border: '1px solid grey',
                        borderRadius: '15px',
                        padding: '5px',
                        margin: '5px 5px'
                    }}>
                    <span
                        style={{
                            color: 'whitesmoke',
                            padding: '0 10px',
                            flexGrow: '3',
                            placeSelf: 'center'
                        }}>
                        החלפת תמונת רקע למערכת
                    </span>
                    <Button
                        style={{
                            textAlign: 'center',
                            flexGrow: '1',
                            direction: 'ltr'
                        }}>
                        <input
                            type="file"
                            accept=".jpg"
                            style={{ cursor: 'pointer' }}
                            onChange={uploadImage}
                        />
                    </Button>
                    {previewImage && (
                        <img
                            className={styles.imgPreview}
                            src={previewImage}
                            alt="preview"
                        />
                    )}
                    {selectedImage && (
                        <Button style={{ margin: '0 5px' }} onClick={save}>
                            שמור
                        </Button>
                    )}
                </div>
            </ComponentGuard>
        </DialogBody>
    );
};

export default ProfileDialog;

import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { TvModelDTO } from 'shared';
import { appApiProvider } from './axiosApiProvider';

export class TvModelsService extends QuickApiEntityService<TvModelDTO> {
    constructor() {
        super(
            new QuickConsoleLogger('TvModelsService'),
            appApiProvider,
            'tvModels',
            'TvModel'
        );
    }
}

export const tvModelsService = new TvModelsService();

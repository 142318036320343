import { QuickApiEntityService, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { DeviceFetchDTO } from 'shared';
import { appApiProvider } from './axiosApiProvider';

export class DeviceService extends QuickApiEntityService<DeviceFetchDTO> {
    constructor() {
        super(
            new QuickConsoleLogger('DevicesService'),
            appApiProvider,
            'device',
            'Device'
        );
    }

    public async mute(
        devicesIds: number[],
        isMute: boolean,
        groupID: number
    ): Promise<DeviceFetchDTO[]> {
        return this.postOrThrow({ devicesIds, isMute, groupID }, { url: '/mute' });
    }

    public async volume(
        devicesIds: number[],
        volumeToAdd: number,
        groupID: number
    ): Promise<DeviceFetchDTO[]> {
        return this.postOrThrow(
            { devicesIds, volumeToAdd, groupID },
            { url: '/changeVolume' }
        );
    }

    public async changeChannel(
        devicesIds: number[],
        channelURL: string,
        groupID: number
    ): Promise<DeviceFetchDTO[]> {
        return this.postOrThrow(
            { devicesIds, channelURL, groupID },
            { url: 'changeChannel' }
        );
    }

    public async power(
        devicesIds: number[],
        offStatus: number,
        groupID: number
    ): Promise<DeviceFetchDTO[]> {
        return this.postOrThrow({ devicesIds, offStatus, groupID }, { url: '/power' });
    }

    public async resetPi(deviceId: number) {
        // POST device/reset
        return this.postOrThrow({ deviceId }, { url: '/reset' });
    }

    public async changeHDMI(devicesIds: number[], hdmiInput: number, groupID: number) {
        return this.postOrThrow(
            { devicesIds, hdmiInput, groupID },
            { url: '/changeHDMI' }
        );
    }
}

export const deviceService = new DeviceService();

import { styled } from '@mui/material';

export const ContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    overflowY: 'auto'
});

export const ContentNoDataWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center'
});

export const InfoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '10px 0px',
    flexWrap: 'wrap',
    WebkitFlexWrap: 'wrap'
});

export const SectionInfoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '25%'
});

export const ScanImage = styled('img')({
    height: '100%'
});

import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { appNavigator } from 'router';
import { GeneralNavRoutes } from 'shared';
import {
    actions,
    getCurrentUser,
    getCurrentUserInitials,
    getUserSelections,
    store
} from 'store';
import { Avatar, Box, Menu, MenuItem, Typography, IconButton } from '@mui/material';
import classnames from 'classnames';
import { DialogRes } from './ProfileDialog/profileDialog.config';
import { useDialog } from 'quickode.infrastructure.react';
import styles from './profile.module.scss';
import ProfileDialog from './ProfileDialog/ProfileDialog';

interface ProfileProps {
    open: boolean;
}

const Profile: FC<ProfileProps> = ({ open }) => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const { openDialog } = useDialog();

    const currentUserInitials = useSelector(getCurrentUserInitials);
    const currentUser = useSelector(getCurrentUser);

    const handleOpenUserMenu = async (event: MouseEvent<HTMLElement>) => {
        const res: DialogRes = await openDialog({
            render: (handleClose: (res: DialogRes) => void) => {
                return <ProfileDialog handleClose={handleClose} />;
            }
        });

        if (res.actionCode === 'Save') {
            actions.userSelections.partialUpdate({
                openProfile: Boolean(event.currentTarget)
            });
        }
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        appNavigator.navigate(GeneralNavRoutes.Logout);
    };

    return (
        <div
            className={classnames({
                [styles.profile]: true,
                [styles.isSidemenuOpen]: !open
            })}>
            <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu}>
                    <Avatar sx={{ width: '60px', height: '60px' }}>
                        {currentUser?.img ? (
                            <img
                                src={currentUser.img}
                                alt="me"
                                style={{ width: '60px' }}
                            />
                        ) : (
                            currentUserInitials
                        )}
                    </Avatar>
                </IconButton>
            </Box>
            {currentUser && (
                <div className={styles.currentUser}>
                    {`${currentUser.firstName} ${currentUser.lastName}`}
                    {/* {`${currentUser.email}`} */}
                </div>
            )}
        </div>
    );
};

export default Profile;

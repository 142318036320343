import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactComponent as ErrorIcon } from '../assets/images/svg/messagebox-error.svg';
import { ReactComponent as QuestionIcon } from '../assets/images/svg/messagebox-question.svg';
import { ReactComponent as SuccessIcon } from '../assets/images/svg/messagebox-success.svg';
import { QuickMessageBoxCancel, QuickMessageBoxType } from './quick-message-box.model';
import './quick-message-box.scss';
import { getQuickMesssageBoxState } from './quick-message-box.selector';

const iconMap: { [key in QuickMessageBoxType]: JSX.Element } = {
    error: <ErrorIcon />,
    success: <SuccessIcon />,
    question: <QuestionIcon />,
    simpleAlert: undefined
};

export const QuickMessageBox = () => {
    const messageBox = useSelector(getQuickMesssageBoxState);

    if (!messageBox) {
        return null;
    }

    const { title, content, buttons = [], allowCancel, onUserClick, type } = messageBox;

    const onCloseDialog = (reason: string, result?: any) => {
        const backdropEscapeReason =
            reason === 'backdropClick' || reason === 'escapeKeyDown';
        if ((backdropEscapeReason && allowCancel) || !backdropEscapeReason) {
            onUserClick(backdropEscapeReason ? QuickMessageBoxCancel : result);
        }
    };

    return (
        <Dialog
            className="mb-dialog"
            open={true}
            disableEscapeKeyDown={!allowCancel}
            onClose={(event, reason) => {
                onCloseDialog(reason);
            }}>
            <DialogTitle>
                <IconButton
                    className="mb-close-icon"
                    aria-label="close"
                    onClick={() => onCloseDialog('buttonClick', QuickMessageBoxCancel)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={
                    type === QuickMessageBoxType.SimpleAlert
                        ? 'mb-simple-alert-dialog-content'
                        : 'mb-dialog-content'
                }>
                <DialogContentText className="mb-dialog-content-text">
                    {iconMap[type]}
                    {title && <span className="mb-title">{title}</span>}
                    {typeof content === 'string' ? (
                        <span
                            className={
                                type === QuickMessageBoxType.SimpleAlert
                                    ? 'mb-simple-alert-content'
                                    : 'mb-content'
                            }>
                            {content}
                        </span>
                    ) : (
                        content
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="mb-dialog-actions">
                {buttons.map(button => (
                    <Button
                        variant="contained"
                        key={`mb-btn-${button.text}`}
                        className="mb-dialog-button"
                        onClick={() => {
                            onCloseDialog('buttonClick', button.buttonResult);
                        }}>
                        {button.text}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

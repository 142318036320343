import { createSelector } from 'reselect';
import { isEmptyObj } from 'shared';
import { AppState } from 'store/store';

const getUser = (state: AppState) => state.user;

export const getCurrentUser = createSelector(getUser, user =>
    isEmptyObj(user) ? null : user
);

export const getCurrentUserInitials = createSelector(getCurrentUser, user => {
    const firstNameInitial = user?.firstName ? user.firstName[0] : '';
    const lastNameInitial = user?.lastName ? user.lastName[0] : '';

    return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
    // return `${user?.email}`.toUpperCase();
});

export const getCurrentUserRole = createSelector(getCurrentUser, user => user?.role);

import { styled, TableCell, Tooltip } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import {
    QuickColumn,
    QuickColumnIcon,
    QuickColumnText,
    QuickDataTableCellType
} from '../data-columns';

const QuickTableCellWrapped = styled(TableCell)({
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});

export const StyledAnchor = styled('a')(({ theme }) => {
    let hoverTextColor = '#209AFF';
    let activeTextColor = '#FF0000';

    if (theme?.palette?.info && theme.palette?.info?.contrastText) {
        hoverTextColor = theme.palette.info.contrastText;
    }
    if (theme?.palette?.primary && theme?.palette?.primary?.main) {
        activeTextColor = theme.palette.primary.main;
    }

    return {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: hoverTextColor,
            textDecoration: 'underline'
        },
        '&:active': {
            color: activeTextColor,
            textDecoration: 'underline'
        }
    };
});

const createDefaultCell = (
    cellKey: string,
    cellContent: any,
    col: QuickColumn<any, any>
) => (
    <TableCell
        key={cellKey}
        width={col.width}
        // I've added that line because when we're wrapping the cell, we need to add a maxWidth as well.
        style={{ maxWidth: col.width, display: col.isHidden ? 'none' : 'table-cell' }}>
        {cellContent}
    </TableCell>
);

const cellsMapper: {
    [key in QuickDataTableCellType]: (
        // I don't use the quicColum interface because I would like to set it for every type differenty depends
        // on the type it's working with
        col: any,
        cellValue: any,
        cellKey: string,
        row: any
    ) => JSX.Element;
} = {
    TEXT: (col: QuickColumnText<any>, cellValue: string, cellKey: string, row) => {
        let tooltip: string = null;

        if (col.tooltip) {
            tooltip = col.tooltip(row);
        } else if (col.wrap) {
            tooltip = cellValue;
        }

        let contentToRender: string | JSX.Element = cellValue;

        if (col.hyperlink || col.onClick) {
            contentToRender = (
                <StyledAnchor
                    color="primary"
                    href={col.hyperlink}
                    onClick={() => col.onClick(row)}>
                    {cellValue}
                </StyledAnchor>
            );
        }

        if (tooltip) {
            return (
                <Tooltip key={cellKey} title={tooltip} placement="top">
                    <QuickTableCellWrapped
                        width={col.width}
                        style={{ maxWidth: col.width }}>
                        {contentToRender}
                    </QuickTableCellWrapped>
                </Tooltip>
            );
        }

        return createDefaultCell(cellKey, contentToRender, col);
    },

    ICON: (col: QuickColumnIcon<any>, cellValue: string, cellKey: string) =>
        createDefaultCell(
            cellKey,
            <img src={cellValue} alt="icon" width={col.width} height={col.width} />,
            col
        ),

    BOOLEAN: (col: QuickColumnText<any>, cellValue: boolean, cellKey: string) =>
        createDefaultCell(cellKey, cellValue ? <CheckIcon /> : <ClearIcon />, col),
    DATE: (col: QuickColumnText<any>, cellValue: boolean, cellKey: string) =>
        createDefaultCell(cellKey, cellValue, col),

    MENU: () => {
        throw new Error('Menu is not supported (yet)');
    }
};

export const getCellToRender = <TRow extends any>(
    rowKeyResolver: (row: TRow) => string,
    col: QuickColumn<TRow, any>,
    row: TRow,
    index: number
): JSX.Element => {
    const renderingFunc = cellsMapper[col.type];

    if (!renderingFunc) {
        console.error(`Cannot render cell with unknown type ${col.type}, return null`);

        return null;
    }

    return renderingFunc(
        col,
        col.value(row, index),
        `row-cell-${col.key}-${rowKeyResolver(row)}`,
        row
    );
};

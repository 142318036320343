import { Box } from '@mui/material';
import {
    Section,
    MappingFieldsType,
    MainSectionsType,
    AdditionalSectionsType
} from '../Form/formTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { useBreakpointsMediaQuery } from 'hooks';
import EntityFormActionsMobile from 'components/EntityFormActionsMobile/EntityFormActionsMobile';
import EntityFormSections from 'components/EntityFormSections/EntityFormSections';
import { useEffect } from 'react';
import EntityFormActionsDesktop from 'components/EntityFormActionsDesktop/EntityFormActionsDesktop';

interface FormWrapperProps<TEntity, TEntityForm> {
    isEditMode: boolean;
    formTitle: string;
    className: string;
    entityFields: MappingFieldsType<string>;
    entityAdditionalSections?: Section<string>[];
    entityFieldValues: Record<string, string | number | boolean | number[]>;
    onSubmit: (entity: any) => void;
    onDelete: () => void;
}

const EntityForm = <TEntity extends unknown, TEntityForm extends unknown>({
    isEditMode,
    formTitle,
    className,
    entityFields,
    entityAdditionalSections,
    entityFieldValues,
    onSubmit,
    onDelete
}: FormWrapperProps<TEntity, TEntityForm>) => {
    const methods = useForm<
        Record<keyof typeof entityFields, string | number | boolean | number[]>
    >({ mode: 'all', reValidateMode: 'onChange' });

    const { handleSubmit, reset, formState, setValue } = methods;

    // formState.isValid is false after reset is called with values provided https://github.com/react-hook-form/react-hook-form/issues/2755
    const { isDirty, isValid } = formState;

    useEffect(() => {
        reset(entityFieldValues);
    }, [entityFieldValues]);

    const isLaptops = useBreakpointsMediaQuery('breackpointLaptops');

    const isSaveValid = !(isDirty && isValid);

    // setValue from useFormContext does not affect isDirty. link: https://github.com/react-hook-form/react-hook-form/issues/72#issuecomment-1096813727
    const sections: [MainSectionsType<string>, ...AdditionalSectionsType<string>[]] = [
        {
            title: formTitle,
            fields: entityFields,
            actionsComponent: (
                <EntityFormActionsDesktop
                    isEditMode={isEditMode}
                    isSaveValid={isSaveValid}
                    isLaptops={isLaptops}
                    onDelete={onDelete}
                />
            )
        },
        ...(entityAdditionalSections || [])
    ];

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} className={className}>
                <EntityFormSections
                    sections={sections}
                    entityFieldValues={entityFieldValues}
                    setValue={setValue}
                />
                {!isLaptops && <EntityFormActionsMobile isSaveValid={isSaveValid} />}
            </Box>
        </FormProvider>
    );
};

export default EntityForm;

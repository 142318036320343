import classNames from 'classnames';
import styles from './activeMode.module.scss';

interface ActiveModeProps {
    isActive: boolean;
}

export const ActiveMode = ({ isActive }: ActiveModeProps) => {
    const text = isActive ? 'פעיל' : 'לא פעיל';
    return (
        <div
            className={classNames({
                [styles.activeMode]: true,
                [styles.active]: isActive
            })}>
            <div className={styles.dot} />
            {text}
        </div>
    );
};

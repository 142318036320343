import { RoleEnum } from 'shared';

export enum GeneralNavRoutes {
    Main = '',
    Login = 'auth/login',
    Logout = 'auth/logout',
    Channels = '/channels',
    Groups = '/groups',
    Devices = '/devices',
    Users = 'users',
    Messages = 'messages'
}
export interface SideMenuRoute {
    authRoles: RoleEnum[];
    path: string;
    icon: JSX.Element;
    translationKey: string;
}

export interface IRoute {
    authRoles?: RoleEnum[];
    layout?: 'main' | 'auth';
    renderSource: { moduleToLoad: string } | { componentToRender: () => JSX.Element };
}

interface AppRoute extends IRoute {
    sideMenuProps?: Omit<SideMenuRoute, 'authRoles' | 'path'>;
}

export type GeneralRoutes = {
    [key in GeneralNavRoutes]: AppRoute;
};

export interface AppNavParams {
    [GeneralNavRoutes.Login]: void;
    [GeneralNavRoutes.Logout]: void;
    [GeneralNavRoutes.Main]: void;
    [GeneralNavRoutes.Channels]: void;
    [GeneralNavRoutes.Groups]: void;
    [GeneralNavRoutes.Devices]: void;
    [GeneralNavRoutes.Users]: void;
}

import {
    createTypedTranslationKey,
    quickTranslate,
    quickTranslateSimple
} from 'quickode.infrastructure.js';
import { useSelector } from 'react-redux';

import { createObjectState, QuickReduxDispatch } from '../state';
import { getCurrentTranslation } from './translate.selector';
import { QuickTranslation } from './translation.model';

export const useQuickTranslate = <T>() => {
    type RootKeys = keyof T;

    const currentTranslation = useSelector(getCurrentTranslation);

    return {
        isRtl: currentTranslation.isRtl,
        createTranslationKey: createTypedTranslationKey<T>(),
        simpleTranslate: (key: string, config?: { [key: string]: string }) =>
            quickTranslateSimple(currentTranslation, key, config),

        translate: <
            TRootKey extends RootKeys,
            TSecondKey extends keyof T[TRootKey],
            TThirdKey extends T[TRootKey][TSecondKey]
        >(
            rootKey: TRootKey,
            secondKey: TSecondKey,
            thirdKey?: keyof Omit<TThirdKey, keyof string>,
            config?: { [key: string]: string }
        ) =>
            quickTranslate<T, TRootKey, TSecondKey, TThirdKey>(
                currentTranslation as T,
                rootKey,
                secondKey,
                thirdKey,
                config
            )
    };
};

export const appendTranslation = <T>(
    dispatch: QuickReduxDispatch,
    defaultTranslations: QuickTranslation<T>
) => {
    return {
        translation: createObjectState<QuickTranslation<T>>('translation', dispatch, {
            initialValue: defaultTranslations
        })
    };
};

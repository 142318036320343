import { UserTableDto } from 'api/dto/user.service.dto';
import { QuickTableFetchedData } from 'quickode.infrastructure.js';
import { RoleEnum, User } from 'shared';

export const mockUserData: User = {
    id: 123,
    firstName: 'User',
    lastName: 'Mock',
    email: 'mock@test.com',
    role: RoleEnum.Admin,
    createdAt: new Date('2022-11-17T10:34:33.961Z'),
    updatedAt: new Date('2022-11-17T10:34:33.961Z'),
    phone: '0547631020'
};

export const mockUsersTableData: QuickTableFetchedData<UserTableDto> = {
    currentPage: 1,
    pageSize: 10,
    rows: [
        {
            id: 7,
            companyId: 1,
            companyName: 'Company 1',
            firstName: 'Name1',
            lastName: 'Last1',
            email: 'test0@test.com',
            phone: '0547632011',
            role: RoleEnum.ResearchAndDevelopment,
            createdAt: new Date('2022-11-17T10:34:33.961Z')
        },
        {
            id: 6,
            companyId: 2,
            companyName: 'Company 2',
            firstName: 'Name2',
            lastName: 'Last2',
            email: 'test1@test.com',
            phone: null,
            role: RoleEnum.Operator,
            createdAt: new Date('2022-11-17T10:34:33.961Z')
        },
        {
            id: 5,
            companyId: 3,
            companyName: 'Company 3',
            firstName: 'Name3',
            lastName: 'Last3',
            email: 'test2@test.com',
            phone: '0547632010',
            role: RoleEnum.Admin,
            createdAt: new Date('2022-11-17T10:34:33.961Z')
        }
    ],
    totalItems: 3
};

import { QuickStateObjectActions } from 'quickode.infrastructure.react/state/state-object/state-object-actions';
import { useQuickTranslate } from 'quickode.infrastructure.react/translation';
import { useCallback } from 'react';
import {
    QuickMessageBoxButton,
    QuickMessageBoxItem,
    QuickMessageBoxType,
    QuickMessageInfoType
} from './quick-message-box.model';

export let messageBoxActions: QuickStateObjectActions<QuickMessageBoxItem> = null;

export const setMessageBoxActions = (
    action: QuickStateObjectActions<QuickMessageBoxItem>
) => (messageBoxActions = action);

export const useMessageBox = () => {
    const { translate } = useQuickTranslate<any>();

    const showMessageBox = useCallback(
        <T = any>(
            type: QuickMessageBoxType,
            title: string,
            content: string | JSX.Element,
            buttons: QuickMessageBoxButton[],
            allowCancel?: boolean
        ) =>
            new Promise<T>(resolve => {
                const messageBox: QuickMessageBoxItem = {
                    type,
                    title,
                    content,
                    buttons,
                    allowCancel: Boolean(allowCancel),
                    onUserClick: (result?: any) => {
                        messageBoxActions.clear();
                        resolve(result);
                    }
                };

                messageBoxActions.set(messageBox);
            }),
        []
    );

    const showInfoMessage = (
        type: QuickMessageInfoType,
        title: string,
        content: string | JSX.Element,
        closeBtnTitle?: string
    ) => {
        showMessageBox(
            type,
            title,
            content,
            [
                {
                    text: closeBtnTitle || translate('common', 'buttons', 'ok'),
                    buttonResult: undefined
                }
            ],
            true
        );
    };

    const showYesNoDialog = async (
        title: string,
        content: string | JSX.Element,
        options: {
            type?: QuickMessageBoxType;
            okBtnTitle?: string;
            cancelBtnTitle?: string;
            reverseButtons?: boolean;
        } = {}
    ) => {
        const {
            type = QuickMessageBoxType.Question,
            okBtnTitle,
            cancelBtnTitle
        } = options;

        const buttons = [
            {
                text: okBtnTitle || translate('common', 'buttons', 'yes'),
                buttonResult: true
            },
            {
                text: cancelBtnTitle || translate('common', 'buttons', 'no'),
                buttonResult: false
            }
        ];

        return await showMessageBox(
            type,
            title,
            content,
            options.reverseButtons ? buttons.reverse() : buttons,
            false
        );
    };

    const showSimpleAlert = (content: string | JSX.Element, closeBtnTitle?: string) => {
        showMessageBox(
            QuickMessageBoxType.SimpleAlert,
            undefined,
            content,
            [
                {
                    text: closeBtnTitle || translate('common', 'buttons', 'ok'),
                    buttonResult: undefined
                }
            ],
            true
        );
    };

    return { showMessageBox, showInfoMessage, showSimpleAlert, showYesNoDialog };
};

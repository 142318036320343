import { QuickApiServiceBase, QuickConsoleLogger } from 'quickode.infrastructure.js';
import { User } from 'shared';
import { createAppTranslationKey } from 'translation';
import { appApiProvider } from './axiosApiProvider';
import { GeneralError, AppErrorCode } from './dto/generalError';

class AuthenticationService extends QuickApiServiceBase {
    constructor() {
        super(
            new QuickConsoleLogger('AuthenticationService'),
            appApiProvider,
            'auth',
            'Authentication'
        );
    }

    public async login(): Promise<{
        errorTranslationKey?: string;
        user?: User;
    }> {
        try {
            const { data } = await this.get<User | GeneralError>({ url: '/login' });
            if (
                (data as GeneralError).errorCode ===
                AppErrorCode.Authentication_AuthenticationFailed
            ) {
                return {
                    errorTranslationKey: createAppTranslationKey(
                        'auth',
                        'login',
                        'authError'
                    )
                };
            }
            return { user: data as User };
        } catch (err) {
            return {
                errorTranslationKey: createAppTranslationKey(
                    'auth',
                    'login',
                    'unknownError'
                )
            };
        }
    }

    // public resetPasswordByMail(email: string) {
    //     if (useMockData) {
    //         return sleepAsync(1000);
    //     }
    //     return this.postOrThrow({ email }, { url: 'user/forgot-password' });
    // }

    // public setPassword(userId: number, secret: string, passwordToSet: string) {
    //     if (useMockData) {
    //         return sleepAsync(1000);
    //     }
    //     return this.postOrThrow(
    //         { userId, secret, passwordToSet },
    //         { url: 'user/set-password' }
    //     );
    // }
}

export const authService = new AuthenticationService();

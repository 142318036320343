import { FC } from 'react';
import { Toolbar, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/mainLayout/infoSection/Close.svg';
import { ReactComponent as HamburgerIcon } from 'assets/mainLayout/infoSection/Hamburger.svg';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { getIsBackMode } from 'store/selectors/displayMode.selector';
import { colors } from 'layout/theme';
import { setDisplayMode } from 'store';
import styles from './sideMenuControllers.module.scss';

interface SideMenuControllersProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

const SideMenuControllers: FC<SideMenuControllersProps> = ({
    open,
    handleDrawerToggle
}) => {
    const isBackMode = useSelector(getIsBackMode);

    const button = () => {
        if (open) {
            return (
                <IconButton onClick={handleDrawerToggle}>
                    <CloseIcon />
                </IconButton>
            );
        }
        if (isBackMode) {
            return (
                <IconButton onClick={() => setDisplayMode('list')}>
                    <KeyboardArrowRight
                        htmlColor={colors.labelInput}
                        className={styles.keyboardArrowRightIcon}
                    />
                </IconButton>
            );
        }
        return (
            <IconButton onClick={handleDrawerToggle}>
                <HamburgerIcon />
            </IconButton>
        );
    };

    return (
        <Toolbar color="dark" className={styles.sideMenuControllers}>
            {button()}
        </Toolbar>
    );
};

export default SideMenuControllers;

import { Button } from 'components/Button';
import DialogBody from 'components/DialogBody/DialogBody';
import { GenericList } from 'components/GenericList/GenericList';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { InputFilter } from 'components/InputFilter/InputFilter';
import ScrollbarContainner from 'components/ScrollbarContainner/ScrollbarContainner';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserDevices } from 'store';
import { DialogRes } from './userDevicesDialog.config';
import styles from './userDevicesDialog.module.scss';

interface UserDevicesDialogProps {
    handleClose: (res: DialogRes) => void;
}

const UserDevicesDialog = ({ handleClose }: UserDevicesDialogProps) => {
    const userDevices = useSelector(getUserDevices);

    const [userDevicesFilter, setUserDevicesFilter] = useState('');

    const listItems = userDevices
        .filter(
            userDevice =>
                userDevice.neme?.toLowerCase().includes(userDevicesFilter) &&
                userDevice.status
        )
        .map<GenericListItem>(userDevice => ({
            id: userDevice.id,
            onClick: () => handleClose({ actionCode: 'Save', userDevice }),
            columns: [
                [
                    {
                        type: 'title',
                        title: userDevice.neme,
                        subTitle: ''
                    }
                ]
            ]
        }));

    return (
        <DialogBody
            header={<span className={styles.title}>בחירת מכשיר</span>}
            footer={
                <Button onClick={() => handleClose({ actionCode: 'Cancel' })}>
                    ביטול
                </Button>
            }>
            <InputFilter
                filter={userDevicesFilter}
                setFilter={setUserDevicesFilter}
                placeholder="חיפוש מכשיר"
            />
            <ScrollbarContainner>
                <GenericList listItems={listItems} />
            </ScrollbarContainner>
        </DialogBody>
    );
};

export default UserDevicesDialog;

import { Button } from 'components/Button/Button';
import { IconButton } from '@mui/material';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

interface EntityFormActionsDesktopProps {
    onDelete: () => void;
    isLaptops: boolean;
    isEditMode: boolean;
    isSaveValid: boolean;
}

const EntityFormActionsDesktop = ({
    isLaptops,
    isEditMode,
    isSaveValid,
    onDelete
}: EntityFormActionsDesktopProps) => {
    return (
        <>
            {isLaptops && (
                <Button
                    key="submmitBtn"
                    disabled={isSaveValid}
                    type="submit"
                    sx={{ width: '157px' }}>
                    שמירה
                </Button>
            )}
            {isEditMode && (
                <IconButton size="small" onClick={() => onDelete()}>
                    <TrashIcon />
                </IconButton>
            )}
        </>
    );
};

export default EntityFormActionsDesktop;

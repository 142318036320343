export enum ChannelModule {
    RADIO = 'radio',
    TV = 'tv'
}

export interface Channel {
    id: number;
    channelNameEN: string;
    channelNameHE: string | null;
    moduleType: ChannelModule;
    channelLogo: string;
    channelLogoMobile: string;
    categoryId: number;
    address: string;
    ordinalNum: number;
    inFavorites: boolean | false;
}

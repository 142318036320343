import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

export const useIsScrollable = <ElementType extends HTMLElement>(
    ref: RefObject<ElementType> | RefObject<null>,
    setIsScrollable: (isScrollable: boolean) => void | Dispatch<SetStateAction<boolean>>
) => {
    const { ref: refFunc, height = 1 } = useResizeObserver<ElementType>();

    useEffect(() => {
        refFunc(ref.current);
    }, []);

    useEffect(() => {
        setIsScrollable(height < ref.current.scrollHeight);
    }, [height]);
};

// export const getDefaultValues = (fields: any) => {
//     const defaultValues: any = {};

//     if (fields) {
//         Object.keys(fields).forEach((fieldName: string) => {
//             if (fields[fieldName].defaultValue !== undefined) {
//                 defaultValues[fieldName] = fields[fieldName].defaultValue;
//             } else if (
//                 fields[fieldName].type === QFormFieldTypeEnum.Select &&
//                 fields[fieldName].defaultValue === undefined
//             ) {
//                 defaultValues[fieldName] = ''; // to prevent form warning when select has no defaultValues
//             }
//         });
//     }
//     return defaultValues;
// };

export const isEmptyObj = (obj: any): boolean => {
    return obj == null || Object.keys(obj).length === 0;
};

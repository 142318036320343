import { createStyles, makeStyles } from '@mui/styles';

export const useTableHeaderFiltersStyles = makeStyles(() =>
    createStyles({
        filterContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            paddingLeft: 10
        },
        filterDateRangeWrapper: {
            display: 'flex',
            border: '1px solid #dedede',
            // borderRadius: '5%',
            padding: '10px',
            margin: '10px'
        },
        filterDateRange: {
            margin: '0 5px',
            width: '155px'
        },
        filterDateRangeMiddleTo: {
            fontSize: '23px',
            alignSelf: 'center',
            margin: '0 15px'
        },
        inputLabel: {
            padding: '0 10px',
            paddingLeft: 25,
            fontSize: 14
        }
    })
);

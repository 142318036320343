import { useEffect, useState } from 'react';
import { ChannelModule, Channel } from 'shared';
import { channelService } from 'api';
import { actions, getChannels } from 'store';
import { Footer, ChannelCarousel } from 'components';
import { PopUpMessageList } from 'components/PopUpMessageList';
import { useSelector } from 'react-redux';
import {
    CategoryFiltersData,
    FiltersWithConstantValues,
    FiltersWithDynamicValues
} from './main.model';
import styles from './main.module.scss';
import Tabs from './components/Tabs';

const filterChannelsByModule = (module: ChannelModule, channels: Channel[]) =>
    channels.filter(channel => channel.moduleType === module);

const filterChannelsByCategory = (
    filtersData: CategoryFiltersData,
    channels: Channel[]
) => {
    switch (filtersData.type) {
        case FiltersWithConstantValues.ALL:
            return channels;
        case FiltersWithConstantValues.FAVORITES:
            return channels.filter(channel => channel.inFavorites);
        case FiltersWithDynamicValues.CATEGORIES:
            return channels.filter(channel => channel?.categoryId === filtersData.id);
        default:
            return channels;
    }
};

const Main = () => {
    const channels = useSelector(getChannels) || [];

    const [module, setModule] = useState<ChannelModule>(ChannelModule.TV);
    const [category, setCategory] = useState<CategoryFiltersData>({
        type: FiltersWithConstantValues.ALL
    });

    useEffect(() => {
        channelService
            .getEntities()
            .then(data => {
                actions.userSelections.partialUpdate({
                    channel: data.find(channel => channel.moduleType === ChannelModule.TV)
                });
                actions.channels.set(data);
            })
            .catch(err => {
                console.log('Cannot fetch channels, exception has been thrown', err);
            });
    }, []);

    const filteredChannels = filterChannelsByCategory(
        category,
        filterChannelsByModule(module, channels)
    );

    return (
        <div className={styles.mainConatainer}>
            <Tabs
                module={module}
                setModule={setModule}
                category={category}
                setCategory={setCategory}
            />
            <ChannelCarousel channels={filteredChannels} />
            <PopUpMessageList />
            <Footer />
        </div>
    );
};

export default Main;

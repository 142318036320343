import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React from 'react';
import { Tabs } from '@mui/material';
import styles from './tabMenu.module.scss';

interface TabMenuProps {
    selectedTab: { title: string; value: number };
    onTabChange: (selectedTab: { title: string; value: number }) => void;
}
const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};

const TabMenu = ({ selectedTab, onTabChange }: TabMenuProps) => {
    const [tab, setTab] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        selectedTab.value = newValue;
        if (selectedTab.value) {
            selectedTab.title = 'קבוצה';
        } else {
            selectedTab.title = 'מכשיר';
        }
        onTabChange(selectedTab);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className={styles.tab} label="מכשירים" {...a11yProps(0)} />
                    <Tab className={styles.tab} label="קבוצות" {...a11yProps(1)} />
                </Tabs>
            </Box>
        </Box>
    );
};

export default TabMenu;

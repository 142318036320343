export const translations = {
    common: {
        buttons: {
            ok: 'Ok',
            save: 'Save'
        },
        alerts: {
            titleSuccess: 'Well done',
            titleFail: 'Something went wrong...',
            titleWarning: 'Are you sure?'
        }
    },

    user: {
        pageTitle: 'Users Management',

        columns: {
            id: 'User ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            role: 'Role',
            email: 'Email Address',
            phone: 'Phone Number',
            company: 'Company',
            companyName: 'Company',
            createdAt: 'Creation Date'
        },

        tableFilters: {
            company: 'Company',
            role: 'Role'
        },

        form: {
            createTitle: 'Add user',
            editTitle: 'Edit user'
        },

        tableActions: {
            add: 'Add new user',
            edit: 'Edit User',
            resetPassword: 'Reset Password'
        },
        alerts: {
            selectUserContent: 'Please select a user',
            emailWasSentContent: 'A mail was sent to {userFullName}'
        }
    },

    quickTable: {
        noData: 'There is no data available',
        errorLoadingData: 'Error loading data',
        search: 'Search',
        filterSelectNoValue: 'All'
    },

    sideNav: {
        main: 'ראשי',
        channels: 'ערוצים',
        groups: 'קבוצות',
        devices: 'מכשירים',
        users: 'משתמשים',
        messages: 'הודעות מערכת',
        logout: 'התנתקות'
    },
    auth: {
        setPassword: {
            title: 'Set a new password',
            subtitle: `Make it's longer than 8 digits and hard to guess`,
            submitButton: 'Set password',
            alertErrorSetPasswordContent: 'ERROR setting password: please try again',
            alertSetPassSuccessContent:
                'Your password was set successfully, click on to login'
        },

        login: {
            authError: 'Wrong email or password',
            unknownError: 'Unknown Server Error'
        },

        roles: {
            // These weird looking values come from the BE and I want to save a mapping before
            // translating so I'm using these exact values
            Admin: 'Admin',
            Operator: 'Operator',
            ResearchAndDevelopment: 'R&D'
        },
        forgotPassword: {
            alertInstructionsSentToEmailContent: 'Instructions were sent to your mail'
        }
    },
    messages: {
        OK: 'הבנתי'
    },

    validationMessages: {
        emailNotValid:
            'Please enter email address using the following format: user@domain.com.'
    }
};

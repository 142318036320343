import { Accordion, AccordionDetails, AccordionSummary, Avatar } from '@mui/material';
import { UserDTO } from 'shared';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GenericList } from 'components';
import HDMILogo from 'assets/icons/hdmi.svg';
import { GenericListItem } from 'components/GenericList/genericList.config';
import { useSelector } from 'react-redux';
import { getAuthorities } from 'store';
import styles from './user.module.scss';

interface UserProps {
    user: UserDTO;
}

const User = ({
    user: { relatedDevices = [], id, img, roleName, email, phoneNumber }
}: UserProps) => {
    const authorities = useSelector(getAuthorities);

    const listItem: GenericListItem[] = relatedDevices?.map(relatedDevice => ({
        key: relatedDevice.id,
        id: relatedDevice.id,
        columns: [
            [
                {
                    type: 'img',
                    img: HDMILogo,
                    alt: 'hdmiLogo',
                    style: {
                        marginLeft: '20px'
                    }
                },
                {
                    type: 'title',
                    title: relatedDevice.name,
                    subTitle: `מספר ${relatedDevice.id}`,
                    style: {
                        width: '100%',
                        marginLeft: '20px'
                    }
                }
            ],
            [
                {
                    type: 'component',
                    component: (
                        <span>
                            {authorities?.find(
                                auth => auth.id === relatedDevice.authLevelId
                            )?.authDescription || ''}
                        </span>
                    )
                }
            ]
        ]
    }));

    return (
        <>
            <div className={styles.userDetails}>
                <div className={styles.header}>
                    {/* <Avatar className={styles.avatar} alt={`${id}`} src={img}> */}
                    <Avatar className={styles.avatar} alt={`${id}`}>
                        {!img && <h1>{email?.split('@')[0].slice(0, 1)}</h1>}
                    </Avatar>
                    <div className={styles.title}>
                        <span className={styles.name}>{email.split('@')[0]}</span>
                        <span className={styles.role}>{roleName}</span>
                    </div>
                </div>
                <div className={styles.section}>
                    <span className={styles.description}>כתובת מייל:</span>
                    <span className={styles.data}>{email}</span>
                </div>
                <div className={styles.section}>
                    <span className={styles.description}>מספר טלפון:</span>
                    <span className={styles.data}>{phoneNumber}</span>
                </div>
            </div>
            <Accordion
                defaultExpanded
                className={styles.accordion}
                sx={{
                    color: 'inherit',
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}>
                <AccordionSummary
                    classes={{ content: styles.accordionSummary }}
                    expandIcon={<ExpandMoreIcon htmlColor="white" />}>
                    <span className={styles.title}>מכשירים פעילים והרשאות</span>
                    <span className={styles.subTitle}>
                        {relatedDevices.length} מכשירים
                    </span>
                </AccordionSummary>
                <AccordionDetails>
                    <GenericList listItems={listItem} />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default User;

import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Form from 'components/Form/Form';
import { UseFormSetValue } from 'react-hook-form';
import { useBreakpointsMediaQuery } from 'hooks';
import { colors } from 'layout';
import { Section } from 'components/Form/formTypes';
import SectionHeader from '../../SectionContents/SectionHeader.tsx/SectionHeader';
import styles from '../../../entityFormSections.module.scss';

interface AccordionWrraperProps {
    sections?: Section<string>[];
    entityFieldValues: Record<string, string | number | boolean | number[]>;
    setValue: UseFormSetValue<any>;
}

const AccordionWrraper = ({
    sections,
    entityFieldValues,
    setValue
}: AccordionWrraperProps) => {
    const [expanded, setExpanded] = React.useState<number | false>(0);
    const [desctopExpandedMap, setDesctopExpandedMap] = React.useState<
        Map<number, boolean>
    >(new Map());

    // If the selected entity has been changed - expand the accordions
    useEffect(() => {
        setDesctopExpandedMap(new Map());
        setExpanded(0);
    }, [entityFieldValues]);

    // In mobile mode the accordion will be controlled
    // The accordion key string will contain isLaptops to force a rerender
    const isLaptops = useBreakpointsMediaQuery('breackpointLaptops');

    const handleChange =
        (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const accordions = sections.map((props, index) => {
        const { title, fields } = props;
        const isExpanded = desctopExpandedMap.get(index);
        return (
            <Accordion
                /* eslint-disable-next-line react/no-array-index-key */
                key={title + isLaptops + index}
                className={styles.section}
                sx={{
                    color: 'inherit',
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}
                {...(!isLaptops
                    ? {
                          expanded: expanded === index,
                          onChange: handleChange(index)
                      }
                    : {
                          expanded: isExpanded !== false,
                          onChange: () => {
                              const currentExpandedValue = desctopExpandedMap.get(index);
                              setDesctopExpandedMap(
                                  new Map(
                                      desctopExpandedMap.set(
                                          index,
                                          !(
                                              currentExpandedValue ||
                                              currentExpandedValue === undefined
                                          )
                                      )
                                  )
                              );
                          }
                      })}>
                <AccordionSummary
                    classes={{
                        root: styles.sectionHeader,
                        content: styles.sectionHeaderContent
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: colors.white }} />}>
                    <SectionHeader {...props} setValue={setValue} />
                </AccordionSummary>
                <AccordionDetails className={styles.form}>
                    <Form entityFields={fields} />
                </AccordionDetails>
            </Accordion>
        );
    });

    return <div className={styles.entityFormSections}>{accordions}</div>;
};

export default AccordionWrraper;

import { Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import styles from 'components/List/list.module.scss';
import React from 'react';
import { ColumnItem } from './genericList.config';

interface ColumnList {
    column: ColumnItem[];
}

const ColumnList = ({ column }: ColumnList) => {
    const getColumnComponentItem = (item: ColumnItem, index: number) => {
        switch (item.type) {
            case 'title':
                return (
                    <ListItemText
                        key={index}
                        classes={{
                            root: styles.titleContainer,
                            primary: styles.title,
                            secondary: styles.subTitle
                        }}
                        style={item.style}
                        primary={item.title}
                        secondary={item.subTitle}
                    />
                );
            case 'avatarImg':
                return (
                    <ListItemAvatar key={index} style={item.style}>
                        <Avatar alt="" />
                        {/* <Avatar alt="" src={item.avatarImg} /> */}
                    </ListItemAvatar>
                );

            case 'img':
                return (
                    <img
                        key={index}
                        style={item.style}
                        className={styles.img}
                        alt={item.alt}
                        src={item.img}
                    />
                );
            case 'component':
                return (
                    <div key={index} style={item.style}>
                        {item.component}
                    </div>
                );

            default:
                return <div key={index}>item</div>;
        }
    };

    return (
        <div className={styles.column} style={{ display: 'flex', alignItems: 'center' }}>
            {column.map((item, index) => getColumnComponentItem(item, index))}
        </div>
    );
};

export default ColumnList;

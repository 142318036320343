import Form from 'components/Form/Form';
import { Section } from 'components/Form/formTypes';
import { UseFormSetValue } from 'react-hook-form';
import SectionHeader from '../../SectionContents/SectionHeader.tsx/SectionHeader';
import styles from '../../../entityFormSections.module.scss';

interface DivWrraperProps {
    sections: Section<string>;
    setValue: UseFormSetValue<any>;
}

const DivWrraper = ({ sections, setValue }: DivWrraperProps) => {
    const { fields } = sections;

    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader}>
                <div className={styles.sectionHeaderContent}>
                    <SectionHeader {...sections} setValue={setValue} />
                </div>
            </div>
            <div className={styles.form}>
                <Form entityFields={fields} />
            </div>
        </div>
    );
};

export default DivWrraper;

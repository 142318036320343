import { useEffect, useState } from 'react';
import Entity from 'components/Entity/Entity';
import { UserDTO } from 'shared';
import { userService } from 'api/user.service';
import { ListItemType } from 'components';
import { setDisplayMode } from 'store';
import { getIsTextFiltered } from 'utils/filter';
import User from './User';

const Users = () => {
    const [users, setUsers] = useState<UserDTO[]>();
    const [selectedUser, setSelectedUser] = useState<UserDTO>(null);
    const [userFilter, setUserFilter] = useState<string>('');

    useEffect(() => {
        userService
            .getEntities()
            .then(data => {
                setUsers(data);
                setSelectedUser(data[0]);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const updateSelectedEntity = (user?: UserDTO) => {
        setSelectedUser(user);
    };

    const userListItems: ListItemType[] =
        users
            ?.filter(user =>
                getIsTextFiltered(user.email?.toLowerCase(), userFilter?.toLowerCase())
            )
            .map(user => ({
                id: user.id,
                title: user.email || '',
                subTitle: user.roleName || '',
                avatarImg: user.img || user.email.split('@')[0].slice(0, -1),
                onClick: () => {
                    updateSelectedEntity(user);
                    setDisplayMode('form');
                }
            })) || [];

    return (
        <Entity
            type="component"
            creatable={false}
            selectedEntity={selectedUser}
            singularEntityName="משתמש"
            pluralEntityName="משתמשים"
            updateSelectedEntity={updateSelectedEntity}
            filteredEntityListItems={userListItems}
            entityFilter={userFilter}
            setEntityFilter={setUserFilter}
            viewComponent={selectedUser && <User user={selectedUser} />}
        />
    );
};

export default Users;

import { CSSInterpolation, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const getActionBoxShadow = (color: string) => `-20px 0 20px 0 ${color}`;

export const quickTableSimpleClasses = makeStyles((theme: Theme) => {
    let hoverStyles = '#fff';

    if (
        theme.components?.MuiTableRow?.styleOverrides?.root[
            '&:hover' as keyof CSSInterpolation
        ] &&
        theme.components.MuiTableRow.styleOverrides.root[
            '&:hover' as keyof CSSInterpolation
        ]['backgroundColor']
    ) {
        hoverStyles =
            theme.components.MuiTableRow.styleOverrides.root[
                '&:hover' as keyof CSSInterpolation
            ]['backgroundColor'];
    }

    return createStyles({
        bigTitle: {
            marginTop: '40px',
            paddingBottom: '40px',
            fontSize: '1.5vmax',
            width: '100%',
            textAlign: 'center'
        },
        loadingContainer: {
            position: 'absolute',
            top: '0px',
            left: '0px',

            width: '100%',
            height: '100%',

            backgroundColor: 'rgba(181, 181, 181, 0.26)',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        rowActionsContainer: {
            position: 'absolute',
            top: 5,
            right: 0,
            bottom: 5,
            background: '#fff',
            boxShadow: getActionBoxShadow('#fff')
        },
        actionsWrapper: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '5px',
            paddingLeft: '5px',
            boxShadow: getActionBoxShadow(hoverStyles),
            background: hoverStyles
        },
        normalPaginationContainer: {
            display: 'flex',
            position: 'relative',
            maxHeight: 'calc(100vh - 258px)',
            overflow: 'auto',
            overflowX: 'hidden',
            height: '100%'
        },
        stretchPaginationContainer: {
            display: 'flex',
            position: 'relative',
            height: 'calc(100vh - 258px)',
            overflow: 'auto',
            overflowX: 'hidden'
        }
    });
});

import { MouseEventHandler } from 'react';
import { Section } from 'components/Form/formTypes';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import styles from './sectionHeader.module.scss';

const stopPropagation: MouseEventHandler<HTMLDivElement> = e => e.stopPropagation();

interface SectionHeaderProps extends Omit<Section<string>, 'fields'> {
    setValue: UseFormSetValue<any>;
}

const SectionHeader = (props: SectionHeaderProps) => {
    const { watch } = useFormContext();
    const { title, subTitle, setValue, ActionsComponent, actionsComponent } = props;

    const actionComponent = ActionsComponent ? (
        <ActionsComponent setValue={setValue} />
    ) : (
        actionsComponent
    );

    return (
        <>
            <div className={styles.titleContainner}>
                <span className={styles.title}>{title}</span>
                {subTitle && (
                    <span className={styles.subTitle}>
                        {typeof subTitle === 'string' ? subTitle : subTitle(watch)}
                    </span>
                )}
            </div>
            <div className={styles.actions} onClick={stopPropagation}>
                {actionComponent}
            </div>
        </>
    );
};

export default SectionHeader;

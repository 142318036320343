/* eslint-disable react/no-array-index-key */
import { List as MuiList, ListItem } from '@mui/material';
import ColumnList from './ColumnList';
import { GenericListItem } from './genericList.config';
import styles from './genericList.module.scss';

interface ListProps<T extends GenericListItem> {
    listItems?: T[];
}

export const GenericList = <TListItem extends GenericListItem>({
    listItems
}: ListProps<TListItem>) => {
    const listItemComponents = listItems?.map(listItem => (
        <ListItem
            key={listItem.id}
            className={styles.listItem}
            onClick={() => {
                listItem.onClick?.();
            }}>
            <div className={styles.innerListItem} style={listItem.rowsStyle}>
                {listItem.columns.map((column, index) => (
                    <ColumnList key={index} column={column} />
                ))}
            </div>
        </ListItem>
    ));

    return (
        <div className={styles.listContainer}>
            <MuiList className={styles.list}>{listItemComponents}</MuiList>
        </div>
    );
};

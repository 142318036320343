import { Section } from 'components/Form/formTypes';
import { UseFormSetValue } from 'react-hook-form';
import AccordionWrraper from './Components/SectionWrrapers/AccordionWrraper/AccordionWrraper';
import DivWrraper from './Components/SectionWrrapers/DivWrraper/DivWrraper';

interface EntityFormSectionsProps {
    sections?: Section<string>[];
    entityFieldValues: Record<string, string | number | boolean | number[]>;
    setValue: UseFormSetValue<any>;
}
const EntityFormSections = ({
    sections,
    entityFieldValues,
    setValue
}: EntityFormSectionsProps) =>
    sections.length === 1 ? (
        <DivWrraper sections={sections[0]} setValue={setValue} />
    ) : (
        <AccordionWrraper
            sections={sections}
            entityFieldValues={entityFieldValues}
            setValue={setValue}
        />
    );

export default EntityFormSections;

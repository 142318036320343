import { Dispatch, SetStateAction } from 'react';
import { List, ListItemType, DragableListObj } from 'components';
import { InputFilter } from 'components/InputFilter/InputFilter';
import { Avatar, IconButton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { getDisplayModeObj } from 'store/selectors/displayMode.selector';
import { setDisplayMode } from 'store';
import ScrollbarContainner from 'components/ScrollbarContainner/ScrollbarContainner';

interface EntityListProps<TListItemType, TEntity> {
    creatable?: boolean;
    singularEntityName: string;
    pluralEntityName: string;
    entitiesListItems: TListItemType[];
    selectedEntity: TEntity;
    updateSelectedEntity: (channel?: TEntity) => void;
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
    dragableListObj: DragableListObj;
    listActionComponent?: JSX.Element;
}

const EntityList = <TListItemType extends ListItemType, TEntity extends { id?: number }>({
    creatable = true,
    singularEntityName,
    pluralEntityName,
    entitiesListItems,
    selectedEntity,
    updateSelectedEntity,
    filter,
    setFilter,
    dragableListObj,
    listActionComponent
}: EntityListProps<TListItemType, TEntity>) => {
    const displayModeObj = useSelector(getDisplayModeObj);

    const onClickCreateHandler = () => {
        updateSelectedEntity();
        if (displayModeObj.isDesktop === false) {
            setDisplayMode('form');
        }
    };

    return (
        <div
            style={{
                height: 'calc(100vh - 140px)',
                display: 'flex',
                flexDirection: 'column',
                margin: '20px 0'
            }}>
            <div
                style={{
                    padding: '0 35px'
                }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline">
                    <div>
                        <h2>רשימת {pluralEntityName}</h2>
                        <h5>
                            {entitiesListItems.length} {pluralEntityName}
                        </h5>
                    </div>
                    {listActionComponent}
                    {creatable && (
                        <IconButton onClick={onClickCreateHandler}>
                            <Avatar
                                sx={{
                                    fontSize: '32.7567px',
                                    color: '#1A1B1D',
                                    bgcolor: '#F9F9F9'
                                }}>
                                +
                            </Avatar>
                        </IconButton>
                    )}
                </Stack>
                <InputFilter
                    placeholder={`חיפוש ${singularEntityName}`}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            <ScrollbarContainner style={{ margin: '15px', padding: '0 15px' }}>
                <List
                    listItems={entitiesListItems}
                    selectedItemId={selectedEntity?.id}
                    dragableListObj={dragableListObj}
                />
            </ScrollbarContainner>
        </div>
    );
};

export default EntityList;

import {
    DragDropContext,
    Droppable,
    DropResult,
    ResponderProvided
} from 'react-beautiful-dnd';
// React.ElementType<HTMLProps<HTMLElement>>;
interface DragableWrapperProps {
    DraggableListItemsComponent: JSX.Element;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

const DragableWrapper = ({
    DraggableListItemsComponent,
    onDragEnd
}: DragableWrapperProps) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {DraggableListItemsComponent}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
);

export default DragableWrapper;

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useTableHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: '10px !important',
            paddingRight: '10px !important'
        },
        title: {
            flex: '1 1 100%'
        },

        buttonsContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
            alignItems: 'center'
        }
    })
);

import { useEffect } from 'react';
import { actions } from 'store';
import { useBreakpointsMediaQuery } from './useBreakpointsMediaQuery';

export type DisplayModeType = 'form' | 'list';

interface IsDescktopObjType {
    isDesktop: true;
}

interface IsMobileObjType {
    isDesktop: false;
    displayMode: DisplayModeType;
}

export type DisplayModeObjType = IsDescktopObjType | IsMobileObjType;

export const useDisplayMode = () => {
    const isDesktop = useBreakpointsMediaQuery('breackpointLaptops');

    useEffect(() => {
        if (isDesktop) {
            actions.displayModeObj.set({ isDesktop });
        } else {
            actions.displayModeObj.set({ isDesktop, displayMode: 'list' });
        }
    }, [isDesktop]);
};
